import React, {useContext, useEffect} from 'react'

import Nav from 'react-bootstrap/Nav'

import {NavLink} from 'react-router-dom'

import {ApiContext} from '../../ApiContext'
import {useQuery} from 'react-query'

const UserNavLinks = ({children}) => {
  const {client} = useContext(ApiContext)
  const {data: me, refetch} = useQuery(["me"], () => {
    return client.fetchMe()
  }, {
    enabled: false
  })

  let renderable

  useEffect(() => {
    if (client) {
      refetch()
    } else {
      console.log("but no client yet")
    }
  }, [client, refetch])

  if (me) {
    if (me.is_admin) {
      renderable = (
        <>
          <Nav>
            <Nav.Link to="/admin/users" as={NavLink}>Users</Nav.Link>
            <Nav.Link to="/admin/components" as={NavLink}>Components</Nav.Link>
            <Nav.Link to="/admin/domains" as={NavLink}>Domains</Nav.Link>
          </Nav>
        </>
      )
    } else {
      renderable = (
        <>
        </>
      )
    }
  }

  return (
    <>
      <Nav className="me-auto">
        <Nav.Link
          to='/domains'
          as={NavLink}
          end
        >
          Domains
        </Nav.Link>
        {children}
      </Nav>
      {renderable}
    </>
  )
}

export default UserNavLinks
