import {useContext} from 'react'

import {ImageUrlSigningContext} from 'ak-react-utils'
import ImageLibraryApiContext from '../ImageLibraryApiContext'

import { layoutConfigFor, imageConfigFor, defaultImgConfig } from '../utils'

import ApiContext from '../ApiContext'

const useRefreshAllImageUrls = () => {
  const {imageLibraryClient} = useContext(ImageLibraryApiContext)
  const {urlSigner} = useContext(ImageUrlSigningContext)
  const {client} = useContext(ApiContext)

  return async (domain) => {
    const images = await imageLibraryClient.getImages()
    const pages = await Promise.all(domain.pages.map((page) => {
      return client.fetchPage(domain.id, page.id)
    }))
    const domainSettings = domain.domain_settings
    console.log("loaded all image metadata")

    const promisedChanges = []

    pages.forEach((page) => {
      const {
        page_image_config,
        section_image_config,
        item_image_config,
      } = layoutConfigFor(page.layout, domainSettings)

      if (page.sections) {
        page.sections.forEach((section) => {
          if (section.items) {
            section.items.forEach((item) => {
              if (item.image_id) {
                const imageConfig = imageConfigFor(item.display_mode, domainSettings.item_display_modes, item_image_config)
                const imgproxyConfig = Object.assign({}, defaultImgConfig, {
                  width: imageConfig.width,
                  height: imageConfig.height,
                })

                const img = images.find(({id}) => id === item.image_id)
                item.image_url = urlSigner.create(img.url, imgproxyConfig)

                promisedChanges.push(client.updateItem(page.domain_id, item.id, item))
              }
            })
          }

          if (section.image_id) {
            const imgConfig = Object.assign({}, defaultImgConfig, {
              width: section_image_config.width,
              height: section_image_config.height,
            })

            const img = images.find(({id}) => id === section.image_id)
            section.image_url = urlSigner.create(img.url, imgConfig)

            // Remove items from payload to avoid errors on updateSection
            delete section.items
            promisedChanges.push(client.updateSection(page.domain_id, section.id, section))
          }
        })
      }

      if (page.image_id) {
        const imgConfig = Object.assign({}, defaultImgConfig, {
          width: page_image_config.width,
          height: page_image_config.height,
        })

        const img = images.find(({id}) => id === page.image_id)
        page.image_url = urlSigner.create(img.url, imgConfig)

        // Remove sections from payload to avoid errors on updatePage
        delete page.sections

        promisedChanges.push(client.updatePage(page.domain_id, page.id, page))
      }
    })

    await Promise.all(promisedChanges)

    console.log("changed all the pages")
  }
}

export default useRefreshAllImageUrls
