import React from 'react'

import {FieldArray, useFormikContext} from 'formik'

import MenuEntryFields from './MenuEntryFields'

const MenuEntriesFieldArray = ({name, pages}) => {
  const {values} = useFormikContext()

  const entryActionButtons = ({index, numEntries, helpers}) => (
    <>
      {index > 0 && (
        <button
          type='button'
          className='btn btn-sm btn-outline-secondary me-2'
          onClick={() => helpers.swap(index, index-1)}
        >
          Move higher
        </button>
      )}
      {index < (numEntries - 1) && (
        <button
          type='button'
          className='btn btn-sm btn-outline-secondary me-2'
          onClick={() => helpers.swap(index, index+1)}
        >
          Move lower
        </button>
      )}
      <button
        type='button'
        className='btn btn-sm btn-outline-danger'
        onClick={() => helpers.remove(index)}
      >
        Remove Menu Entry
      </button>
    </>
  )

  return (
    <FieldArray name={name}>
      {(helpers) => (
        <div className='menu-entries mb-5'>
          {values[name].length > 0 &&
           values[name].map((entry, index) => (
             <div className='ak-menu-entry' key={index}>
               <FieldArray name={`${name}.${index}.entries`}>
                 {(subhelpers) => (
                   <>
                     <div className='ak-menu-entry-content'>
                       <MenuEntryFields
                         pages={pages}
                         baseName={`${name}.${index}`}
                       />
                       <div className='ak-actions ak-actions-menu-entry'>
                         <button
                           type='button'
                           className='btn btn-sm btn-outline-success me-2'
                           onClick={() => {
                             subhelpers.push({target_text: '', entries: []})
                           }}
                         >
                           New Sub Menu Entry
                         </button>
                         {entryActionButtons({
                           index,
                           numEntries: values[name].length,
                           helpers
                         })}
                       </div>
                     </div>
                     <div>
                       {values[name][index].entries.length > 0 &&
                        values[name][index].entries.map((subentry, subindex) => (
                          <div className='ak-menu-entry' key={`${index}-${subindex}`}>
                            <FieldArray name={`${name}.${index}.entries.${subindex}.entries`}>
                              {(subsubhelpers) => (
                                <>
                                  <div className='mb-3 ps-4 ak-sub-menu-entry-content'>
                                    <MenuEntryFields
                                      pages={pages}
                                      baseName={`${name}.${index}.entries.${subindex}`}
                                    />
                                    <div className='ak-actions ak-actions-sub-menu-entry'>
                                      <button
                                        type='button'
                                        className='btn btn-sm btn-outline-success me-2'
                                        onClick={() => {
                                          subsubhelpers.push({target_text: '', entries: []})
                                        }}
                                      >
                                        New Subsub Menu Entry
                                      </button>
                                      {entryActionButtons({
                                        index: subindex,
                                        numEntries: values[name][index].entries.length,
                                        helpers: subhelpers
                                      })}
                                    </div>
                                  </div>
                                  <div>
                                    {values[name][index].entries[subindex].entries.map((subsubentry, subsubindex) => (
                                      <div className='ak-menu-entry' key={`${index}-${subindex}-${subsubindex}`}>
                                        <div className='ps-5 ak-sub-sub-menu-entry-content'>
                                          <MenuEntryFields
                                            pages={pages}
                                            baseName={`${name}.${index}.entries.${subindex}.entries.${subsubindex}`}
                                          />
                                          <div className='ak-actions ak-actions-sub-sub-menu-entry'>
                                            {entryActionButtons({
                                              index: subsubindex,
                                              numEntries: values[name][index].entries[subindex].entries.length,
                                              helpers: subsubhelpers
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </>
                              )}
                            </FieldArray>
                          </div>
                       ))}
                     </div>
                   </>
                 )}
               </FieldArray>
             </div>
          ))}
          <button
            type='button'
            className='btn btn-outline-success'
            onClick={() => {
              helpers.push({target_text: '', entries: []})
            }}
          >
            New Menu Entry
          </button>
        </div>
      )}
    </FieldArray>
  )
}

export default MenuEntriesFieldArray
