import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import {Link} from 'react-router-dom'

const Navigation = ({children}) => {
  return (
    <Navbar bg="dark" variant="dark" expand="lg" className='mb-5'>
      <Container>
        <Navbar.Brand to='/' as={Link}>AkCmsAdmin</Navbar.Brand>
        <Navbar.Toggle aria-controls="ak-navbar-nav" />
        <Navbar.Collapse id="ak-navbar-nav">
          {children}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Navigation
