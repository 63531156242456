import {useContext} from 'react'

import ApiContext from '../ApiContext'
import {useParams} from 'react-router-dom'
import {useQuery} from 'react-query'

const useAdminDomain = () => {
  const {client} = useContext(ApiContext)
  const {domainId} = useParams()
  return useQuery(['adminDomains', domainId], () => client.fetchAdminDomain(domainId))
}

export default useAdminDomain
