import React, {useContext} from 'react'
import AuthContext from '../AuthContext'
import {Navigate, useLocation} from 'react-router-dom'
import {createApiClient} from '../ApiClient'
import {ApiContextProvider} from '../ApiContext'

const RequireApi = ({apiUrl, children}) => {
  const {isLoggedIn, accessToken} = useContext(AuthContext)
  const location = useLocation()

  if (isLoggedIn) {
    const client = createApiClient({apiUrl, apiToken: accessToken})
    return (
      <ApiContextProvider apiClient={client}>
        {children}
      </ApiContextProvider>
    )
  } else {
    return (<Navigate to="/login" state={{ from: location } }/>)
  }
}

export default RequireApi
