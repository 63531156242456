import React, {useState} from 'react'
import PropTypes from 'prop-types'

import Modal from 'react-bootstrap/Modal'
import {useField, useFormikContext} from 'formik'

import ImagePickerBrowser from './Browser'

const ImagePicker = (props) => {
  const {isSubmitting, setFieldValue, setFieldTouched} = useFormikContext()
  const [showModal, setShowModal] = useState(false)

  const [field, ] = useField(props)

  const openModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const chooseImage = (img) => {
    setFieldValue(field.name, img.id)
    setFieldTouched(field.name)
  }

  const removeImage = () => {
    setFieldValue(field.name, null)
    setFieldTouched(field.name)
  }

  return (
    <div>
      <button
        type='button'
        disabled={isSubmitting}
        onClick={openModal}
        className='btn btn-sm btn-outline-info me-2'
      >
        Select from Library
      </button>
      {field.value
      ? (
        <button
          type='button'
          disabled={isSubmitting}
          onClick={removeImage}
          className='btn btn-sm btn-outline-danger'
        >
          Remove Image
        </button>
      )
      : (
        <></>
      )}
      <Modal
        show={showModal}
        onHide={closeModal}
        contentClassName='bg-dark text-light'
        dialogClassName='modal-xl'
      >
        <Modal.Header
          closeButton={true}
          closeVariant='white'
        >
          <Modal.Title>ImageLibrary</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-0">
          <div className='image-picker bg-dark text-light'>
            <ImagePickerBrowser
              onSelectImage={(img) => {
                chooseImage(img)
                closeModal()
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

ImagePicker.propTypes = {
  children: PropTypes.oneOf([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
}

export default ImagePicker
