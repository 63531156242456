import React, {useContext} from 'react'

import {Spinner} from 'react-bootstrap'
import {useParams, Link} from 'react-router-dom'
import {useQuery} from 'react-query'
import ApiContext from '../ApiContext'
import ListGroup from 'react-bootstrap/ListGroup'

const Menu = () => {
  const {menuId, domainId} = useParams()
  const {client} = useContext(ApiContext)
  const {isLoading, isError, error, data} = useQuery(
    ['domains', domainId, 'menus', menuId],
    () => client.fetchMenu(domainId, menuId)
  )

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (isError) {
    return (<span className='text-danger text-center'>{error}</span>)
  }

  const {name, display_name, entries} = data

  return (
    <>
      <h1>{display_name} <small><code>({name})</code></small></h1>
      <ListGroup className='mb-3'>
        {entries.map((entry, index) => (
          <ListGroup.Item key={index}>
            <div>
              {entry.target_text}
            </div>
            {entry.entries.length > 0 && (
              <ListGroup className='my-2'>
                {entry.entries.map((subentry, subindex) => (
                  <ListGroup.Item
                    key={`${index}-${subindex}`}
                  >
                    <div>
                      {subentry.target_text}
                    </div>
                    {subentry.entries.length > 0 && (
                      <ListGroup className='my-2'>
                        {subentry.entries.map((subsubentry, subsubindex) => (
                          <ListGroup.Item
                            key={`${index}-${subindex}-${subsubindex}`}
                          >
                            {subsubentry.target_text}
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    )}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
          </ListGroup.Item>
        ))}
      </ListGroup>
      <Link
        className='btn btn-outline-primary me-2'
        to={`/domains/${domainId}/menus/${menuId}/edit`}
      >
        Edit
      </Link>
      <Link
        className='btn btn-outline-secondary'
        to={`/domains/${domainId}/menus`}
      >
        All Menus
      </Link>
    </>
  )
}

export default Menu
