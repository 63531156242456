import React, {useContext} from 'react'

import {Spinner} from 'react-bootstrap'
import {useParams} from 'react-router-dom'
import {Formik, Form, Field} from 'formik'
import {useQuery, useQueryClient, useMutation} from 'react-query'

import {ApiContext} from '../ApiContext'
import WordCounter from './WordCounter'

const PageMetadataForm = ({onFinish}) => {
  const queryClient = useQueryClient()

  const {domainId, pageId} = useParams()
  const {client} = useContext(ApiContext)

  const {isLoading, isError, error, data} = useQuery(
    ['domains', domainId, 'pages', pageId],
    client.fetchPage(domainId, pageId)
  )

  const updatePage = useMutation(changedPage => {
    return client.updatePage(domainId, pageId, changedPage)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        ['domains', domainId, 'pages', pageId]
      )
    }
  })

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (isError) {
    return (<p className='text-danger text-center'>{error.message}</p>)
  }

  const {
    abstract,
    meta_data: {
      meta_title,
      meta_description,
      meta_keywords,
    },
    in_sitemap
  } = data

  return (
    <>
      <h1>Edit Page Metadata</h1>
      <div className='bg-warning bg-opacity-10 p-3'>
        <Formik
          initialValues={{
            abstract,
            in_sitemap,
            meta_title,
            meta_description,
            meta_keywords
          }}
          onSubmit={(values) => {
            updatePage.mutate(values, {
              onSuccess: onFinish
            })
          }}
        >
          {({isSubmitting, values: { abstract, meta_description, meta_title } }) => (
            <Form>
              <div className='mb-3'>
                <label className='form-label'>Abstract</label>
                <Field
                  type='text'
                  as='textarea'
                  rows={5}
                  name='abstract'
                  className='form-control'
                />
                <WordCounter text={abstract} />
                <div className='form-text'>
                  This text will be used when linking items to
                  existing Pages by <em>Select and copy
                  attributes</em>. It should be quite concise to be
                  useful for e.g. teaser texts.
                  Do not use Markdown here!
                </div>
              </div>

              <div className='mb-3'>
                <label className='form-label'>Meta Title</label>
                <Field
                  type='text'
                  name='meta_title'
                  className='form-control'
                />
                <WordCounter text={meta_title} />
              </div>

              <div className='mb-3'>
                <label className='form-label'>Meta Description</label>
                <Field
                  type='text'
                  as='textarea'
                  name='meta_description'
                  rows={2}
                  className='form-control'
                />
                <WordCounter text={meta_description} />
              </div>

              <div className='mb-3'>
                <label className='form-label'>Meta Keywords</label>
                <Field
                  type='text'
                  name='meta_keywords'
                  className='form-control'
                />
              </div>
              <div className='mb-3'>
                <label className='form-label'>
                  <Field
                    type='checkbox'
                    className='me-3'
                    name='in_sitemap'
                  />
                  In Sitemap?
                </label>
              </div>
              <button
                type='submit'
                disabled={isSubmitting}
                className='btn btn-outline-primary me-2'
              >
                Save
              </button>
              <button
                type='button'
                disabled={isSubmitting}
                onClick={onFinish}
                className='btn btn-outline-secondary'
              >
                Cancel
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

export default PageMetadataForm
