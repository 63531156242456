import React, {useContext} from 'react'
import {Spinner} from 'react-bootstrap'
import useComponent from '../hooks/useComponent'
import {useMutation, useQueryClient} from 'react-query'
import {Link, useParams, useNavigate} from 'react-router-dom'

import ApiContext from '../ApiContext'

const Component = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const {client} = useContext(ApiContext)
  const {componentId} = useParams()
  const {isLoading, isError, error, data} = useComponent()

  const destroyComponent = useMutation(() => {
    return client.destroyComponent(componentId)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        ['components']
      )
      navigate("../")
    }
  })

  const handleDestroy = () => {
    if (window.confirm(`Really destroy Component ${componentId}?`)) {
      destroyComponent.mutate()
    }
  }

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (isError) {
    return (<p className='text-danger text-center'>{error.message}</p>)
  }

  return (
    <>
      <h1>{data.display_name}</h1>
      <dl>
        <dt>Name</dt><dd>{data.name}</dd>
        <dt>Display Name</dt><dd>{data.display_name}</dd>
        <dt>Description</dt><dd>
          <p>{data.description}
          </p>
        </dd>
        <dt>Schema</dt>
        <dd>
          <pre>
            <code>
              {data.schema}
            </code>
          </pre>
        </dd>
      </dl>
      <Link
        to={`/admin/components/${data.id}/edit`}
        className='btn btn-outline-primary me-2'
      >
        Edit Component
      </Link>
      <button
        type='button'
        className='btn btn-outline-danger'
        onClick={handleDestroy}
      >
        Destroy Component
      </button>
    </>
  )
}

export default Component
