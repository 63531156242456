import React from 'react'
import {Field} from 'formik'

import PagePicker from './PagePicker'
import UrlPicker from './UrlPicker'
import FieldSelector from './FieldSelector'

const MenuEntryFields = ({pages, baseName}) => (
  <>
    <div className='mb-2'>
      <label className='form-label'>Target Text</label>
      <Field
        name={`${baseName}.target_text`}
        className='form-control'
        type='text'
      />
    </div>
    <div className='mb-2'>
      <label className='form-label'>Target Page</label>
      <FieldSelector
        secondaryField={<UrlPicker name={`${baseName}.target_url`} />}
      >
        <PagePicker
          pages={pages}
          name={`${baseName}.target_page_path`}
          linkTextField={`${baseName}.target_text`}
          type='text'
        />
      </FieldSelector>
    </div>
  </>
)

export default MenuEntryFields
