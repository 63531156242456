import React, {useContext} from 'react'
import {Formik, Form, Field} from 'formik'
import {useMutation, useQueryClient} from 'react-query'
import {ApiContext} from '../ApiContext'

const ClientTokenForm = ({onSuccess, onCancel, id, domainId, ...props}) => {

  const queryClient = useQueryClient()
  const {client} = useContext(ApiContext)

  const createOrUpdateClientToken = useMutation(token => {
    console.log("createOrUpdateToken")
    if (id) {
      return client.updateClientToken(id, token)
    } else {
      console.log("createToken")
      return client.createClientToken(domainId, token)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        ['client_tokens']
      )
    }
  })

  return(
    <div className='bg-warning bg-opacity-10 p-3'>
      <Formik
        initialValues={props}
        onSubmit={(values) => {
          console.log("onSubmit values: ", values)
          createOrUpdateClientToken.mutate(values, {
            onSuccess
          })
        }}
      >
        {({isSubmitting, errors, touched, values}) => (
          <Form>
            <div className='form-group mb-3'>
              <label className='form-label'>Name</label>
              <Field
                type='text'
                name='name'
                autoFocus={true}
                className='form-control'
              />
            </div>
            <button
              type='button'
              className='btn btn-sm btn-outline-secondary me-2'
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type='submit'
              disabled={isSubmitting}
              className='btn btn-sm btn-outline-primary me-2'
            >
              Save
            </button>
          </Form>
        )}

      </Formik>
    </div>
  )
}

export default ClientTokenForm
