import React from 'react'
import {Spinner} from 'react-bootstrap'
import ClientTokenForm from './ClientTokenForm'
import {useNavigate} from 'react-router-dom'
import useAdminDomain from '../hooks/useAdminDomain'

const ClientTokenNew = () => {
  const {isLoading, isError, error, data} = useAdminDomain()

  const navigate = useNavigate()

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (isError) {
    return (<p className='text-danger text-center'>{error.message}</p>)
  }

  const {
    id,
    fqdn,
  } = data

  return(
    <>
      <h1>New ClientToken for {fqdn}</h1>
      <ClientTokenForm
        domainId={id}
        onSuccess={ () => navigate(`/admin/domains/${id}`)}
        onCancel={() => navigate(`/admin/domains/${id}`)}
      />
    </>
  )
}

export default ClientTokenNew
