import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import ApiContext from '../ApiContext'
import { Badge, Spinner } from 'react-bootstrap'
import DomainContext from '../DomainContext'
import { makeItemUtils, makeSectionUtils } from '../utils'

const DisplayModeUsageRow = ({
  name,
  count,
  using_pages,
  displayModeNames,
  defaultDisplayModeName
}) => {
  const { domainId } = useParams()
  const isLegacyDisplayMode = (mode) => mode !== 'Default' && !displayModeNames.includes(mode)

  return (
    <tr className={isLegacyDisplayMode(name) ? 'table-danger' : ''}>
      <td>
        <details>
          <summary className='position-relative'>
            {name}
            {name === 'Default' && (
              <>{' '}<Badge bg='secondary' >{defaultDisplayModeName}</Badge></>
            )}</summary>
          <p className='mt-2 mb-0'>
            Appears on Pages:
          </p>
          <ul className='list-inline'>
            {using_pages.map(page_id => (
              <li key={page_id} className='list-inline-item'>
                <a href={`/domains/${domainId}/pages/${page_id}`}>{page_id}</a>
              </li>
            ))}
          </ul>
        </details>
      </td>
      <td>{count}</td>
    </tr>
  )
}

const DisplayModeUsageTable = ({ usages, displayModes, defaultDisplayMode }) => {
  const usedDisplayModeNames = usages.map(({ name }) => name)
  const displayModeNames = displayModes.map(({ name }) => name)

  const unusedDisplayModes = [...(new Set(displayModeNames))
                              .difference(new Set(usedDisplayModeNames))]

  return (
    <table className='table table-striped table-hover'>
      <thead>
        <tr>
          <th className='w-75'>Name</th>
          <th>Count</th>
        </tr>
      </thead>
      <tbody>
        {usages.map(usage => (
            <DisplayModeUsageRow
              key={`${usage.type}-${usage.name}`}
              {...usage}
              defaultDisplayModeName={defaultDisplayMode.name}
              displayModeNames={displayModeNames}
            />
          ))}
        {unusedDisplayModes.map(name => (
          <tr key={name} className={'table-secondary'}>
            <td>{name}</td>
            <td>0</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const DisplayModeUsage = () => {
  const { domainId } = useParams()
  const { client } = useContext(ApiContext)
  const { domain, sectionDisplayModes, itemDisplayModes } = useContext(DomainContext)
  const { defaultMode: defaultSectionDisplayMode } = makeSectionUtils(domain)
  const { defaultMode: defaultItemDisplayMode } = makeItemUtils(domain)

  const {
    data: displayModeUsage,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ['domains', domainId, 'display_modes'],
    queryFn: async () => {
      return client.fetchDisplayModeUsage(domainId)
    }
  })

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (isError) {
    return (
      <span className='text-danger text-center'>{error}</span>
    )
  }

  let itemDisplayModeUsages = []
  let sectionDisplayModeUsages = []

  for (const usage of displayModeUsage) {
    switch (usage.type) {
      case 'Section':
        sectionDisplayModeUsages.push(usage)
        break
      case 'Item':
        itemDisplayModeUsages.push(usage)
        break
      default:
        console.error('unknown usage type, ignoring', usage)
    }
  }

  return (
    <>
      <h1>Display Mode usage statistics</h1>
      <p>
        Entries listed as <strong>Default</strong> have no explicitly selected Display Mode
        and <em>should</em> render as the currently selected display mode (noted in the badge).
        However, most frontends choose whatever they seem fit as the default display mode.
      </p>
      <h2>Sections</h2>
      <DisplayModeUsageTable
        usages={sectionDisplayModeUsages}
        displayModes={sectionDisplayModes}
        defaultDisplayMode={defaultSectionDisplayMode}
      />
      <h2>Items</h2>
      <DisplayModeUsageTable
        usages={itemDisplayModeUsages}
        displayModes={itemDisplayModes}
        defaultDisplayMode={defaultItemDisplayMode}
      />
    </>
  )
}

export default DisplayModeUsage
