import React from 'react'

import {Formik, Form} from 'formik'
import DynamicField from './DynamicField'

const componentConfigValueToFormValue = (configValue, schemaField) => {
  switch (schemaField.type) {
    case 'boolean':
      return (configValue === true) ? 'on' : (typeof configValue === 'undefined') ? '' : 'off'
    case 'string':
    default:
      return configValue || ''
  }
}

const formValueToComponentConfigValue = (formValue, schemaField) => {
  switch (schemaField.type) {
    case 'boolean':
      return formValue === 'on'
    case 'string':
    default:
      return formValue
  }
}

const formValuesToComponentConfig = (values, componentSchema) => {
  const componentConfig = {}
  for (let field of componentSchema) {
    if (values[field.name] !== '') {
      componentConfig[field.name] = formValueToComponentConfigValue(values[field.name], field)
    }
  }

  return componentConfig
}

const componentConfigToFormValues = (config, componentSchema) => {
  config = config || {}
  const formValues = {}
  for (let field of componentSchema) {
    formValues[field.name] = componentConfigValueToFormValue(config[field.name], field)
  }

  return formValues
}

const ConfigureForm = ({onFinish, componentConfig, ...component}) => {
  const schema = JSON.parse(component.schema)
  const initialValues = componentConfigToFormValues(JSON.parse(componentConfig), schema)
  console.log(initialValues)
  const onSubmit = (values) => {
    const newComponentConfig = formValuesToComponentConfig(values, schema)
    onFinish(newComponentConfig)
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({isSubmitting}) => (
        <Form>
          {schema.map((field) => (
            <div className='form-group' key={field.name}>
              <label>
                {field.name}
              </label>
              <DynamicField
                {...field}
                className='form-control'
                key={field.name}
              />
              <p className='text-muted small'>
                {field.description}
              </p>
            </div>
          ))}
          <button
            type='submit'
            disabled={isSubmitting}
            className='btn btn-outline-success'
          >
            Save
          </button>
        </Form>
      )}
    </Formik>
  )
}

export default ConfigureForm
