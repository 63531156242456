import React, {useContext} from 'react'

import {useParams} from 'react-router-dom'
import {Formik, Form, Field} from 'formik'
import {useQueryClient, useMutation} from 'react-query'

import {ApiContext} from '../ApiContext'
import {MessagesContext} from '../MessagesContext'

const SettingForm = ({onSuccess, onCancel, id, ...props}) => {
  const queryClient = useQueryClient()
  const {domainId} = useParams()
  const {client} = useContext(ApiContext)
  const {addMessage} = useContext(MessagesContext)

  const createOrUpdateSetting = useMutation(setting => {
    if (id) {
      return client.updateSetting(id, setting)
    } else {
      return client.createSetting(domainId, setting)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        ['adminDomains', domainId]
      )
    }
  })

  const validate = (values) => {
    const errors = {}

    try {
      JSON.parse(values.section_display_modes)
    } catch(err) {
      errors.section_display_modes = err.message
    }

    try {
      JSON.parse(values.item_display_modes)
    } catch(err) {
      errors.item_display_modes = err.message
    }

    try {
      JSON.parse(values.layouts)
    } catch(err) {
      errors.layouts = err.message
    }

    return errors
  }

  return (
    <div className='bg-warning bg-opacity-10 p-3'>
      <p>
        Be sure to read the{' '}
        <a
          href='https://github.com/austauschkompass/ak_cms_api#domain-settings'
          target='_blank'
          rel='noreferrer'
        >
          Schema documentation for these settings
        </a>.
      </p>
      <Formik
        initialValues={props}
        validate={validate}
        onSubmit={(values, formikBag) => {
          values.section_display_modes = JSON.parse(values.section_display_modes)
          values.item_display_modes = JSON.parse(values.item_display_modes)
          values.layouts = JSON.parse(values.layouts)

          createOrUpdateSetting.mutate(values, {
            onSuccess: () => {
              formikBag.setSubmitting(false)
              onSuccess()
            },
            onError: (err) => {
              addMessage(`Cannot save Domain Settings: ${err.message}`, 'error')
              formikBag.setSubmitting(false)
            }
          })
        }}
      >
        {({isSubmitting, errors, touched, values: { image_url }}) => (
          <Form>
            <div className='form-group mb-3'>
              <label>Section Display Modes</label>
              <Field
                type='text'
                as='textarea'
                name='section_display_modes'
                rows={5}
                autoFocus={true}
                className='form-control'
              />
              {errors.section_display_modes && touched.section_display_modes && <div className='text-danger'>{errors.section_display_modes}</div>}
            </div>
            <div className='form-group mb-3'>
              <label>Item Display Modes</label>
              <Field
                type='text'
                as='textarea'
                name='item_display_modes'
                rows={5}
                className='form-control'
              />
              {errors.item_display_modes && touched.item_display_modes && <div className='text-danger'>{errors.item_display_modes}</div>}
            </div>
            <div className='form-group mb-3'>
              <label>Layouts</label>
              <Field
                type='text'
                name='layouts'
                rows={10}
                as='textarea'
                className='form-control'
              />
              {errors.layouts && touched.layouts && <div className='text-danger'>{errors.layouts}</div>}
            </div>
            <button
              type='submit'
              disabled={isSubmitting}
              className='btn btn-sm btn-outline-primary me-2'
            >
              Save
            </button>
            <button
              type='button'
              disabled={isSubmitting}
              onClick={onCancel}
              className='btn btn-sm btn-outline-secondary'
            >
              Cancel
            </button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default SettingForm
