import {useContext} from 'react'

import ApiContext from '../ApiContext'
import {useQuery} from 'react-query'

const useDomains = () => {
  const {client} = useContext(ApiContext)
  return useQuery(['domains'], () => client.fetchDomains())
}

export default useDomains
