import React from 'react'
import useMe from '../hooks/useMe'

const RequireAdmin = ({children}) => {
  const {isLoading, isError, error, data} = useMe()

  if (isLoading) {
    return (
      <>
        Is loading
      </>
    )
  }

  if (isError) {
    return (
      <p className='text-danger'>
        {error}
      </p>
    )
  }

  if (data.is_admin) {

    return (
      <>
      {children}
      </>
    )
  } else {
    return (
      <p className='text-warning'>
        the power is NOT with you...
      </p>
    )
  }

}

export default RequireAdmin
