import React from 'react'
import {Spinner} from 'react-bootstrap'
import ClientTokenForm from './ClientTokenForm'
import {useNavigate} from 'react-router-dom'
import useClientToken from '../hooks/useClientToken'

const ClientTokenEdit = () => {
  const navigate = useNavigate()
  const {isLoading, isError, error, data} = useClientToken()

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (isError) {
    return (
      <p className='text-danger text-center'>{error.message}</p>
    )
  }

  return(
    <>
      <h1>Edit ClientToken</h1>
      <ClientTokenForm {...data}
        onSuccess={() => navigate(`/admin/domains/${data.domain.id}`)}
        onCancel={() => navigate(`/admin/domains/${data.domain.id}`)}
      />
    </>
  )
}

export default ClientTokenEdit
