import React, {useContext} from 'react'
import {Alert} from 'react-bootstrap'

import {MessagesContext} from '../MessagesContext'

const Messages = () => {
  const {messages, removeMessage} = useContext(MessagesContext)

  return (
    <div className='sticky-top pt-4'>
    {messages.map(({id, message, type}) =>
      (
        <Alert key={id} variant={type === 'error' ? 'danger' : 'info'}
               onClose={() => removeMessage(id)} dismissible>
        <p className='mb-0'>
          {message}
        </p>
      </Alert>
      )
    )}
    </div>
  )
}

export default Messages
