import React, {useContext, useState} from 'react'
import {useQueryClient, useMutation} from 'react-query'
import {useParams, Link, useNavigate} from 'react-router-dom'

import Toast from 'react-bootstrap/Toast'
import {Table, ToastContainer, Spinner} from 'react-bootstrap'

import useAdminDomain from '../hooks/useAdminDomain'
import ApiContext from '../ApiContext'

const  AdminDomain = () => {
  const [showsCiTriggered, setShowsCiTriggered] = useState(false)
  const {domainId} = useParams()
  const {client} = useContext(ApiContext)
  const {isLoading, isError, error, data} = useAdminDomain()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const destroyClientToken = useMutation((tokenId) => {

    console.log("destroy token...")
    return client.destroyClientToken(tokenId)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        //['client_tokens']
        ['adminDomains', domainId]
      )
    }
  })

  const triggerCi = async () => {
    await client.triggerCi(domainId)

    setShowsCiTriggered(true)
  }

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (isError) {
    return (<p className='text-danger text-center'>{error.message}</p>)
  }

  const {
    id,
    fqdn,
    slug,
    default_path_prefix,
    domain_settings,
    ci_webhook_url,
    client_tokens,
  } = data



  const handleTokenDestroy = (tokenId) => {
    console.log("handling token destroy...")
    if (window.confirm(`Really destroy ClientToken ${tokenId}?`)) {
      destroyClientToken.mutate(tokenId)
    }
  }


  const renderTokenRows = client_tokens.map((token) => {
    return (
      <tr key={token.id}>
        <td>{token.name}</td>
        <td>{token.token}</td>
        <td className='text-end'>
          <button
            type='button'
            className='btn btn-sm btn-outline-primary me-2'
            onClick={() => navigate(`/admin/client_tokens/${token.id}/edit`)} >
            Edit
          </button>
          <button
            type='button'
            className='btn btn-sm btn-outline-danger'
            onClick={() => handleTokenDestroy(token.id)} >
            Destroy
          </button>
        </td>
      </tr>
    )
  })

  return (
    <>
      <ToastContainer position='top-end' className='p-3'>
      <Toast show={showsCiTriggered} onClose={() => setShowsCiTriggered(false)}>
        <Toast.Header>
          <img
            src="holder.js/20x20?text=%20"
            className="rounded me-2"
            alt=""
          />
          <strong className="me-auto">CI Deployment</strong>
        </Toast.Header>
        <Toast.Body>Deployment of {fqdn} successfully triggered!</Toast.Body>
      </Toast>
      </ToastContainer>
      <section className='mb-4'>
        <h1>{fqdn}</h1>
        <dl>
          <dt>ID</dt><dd>{id}</dd>
          <dt>Domain name</dt><dd>{fqdn}</dd>
          <dt>Slug</dt><dd>{slug}</dd>
          {default_path_prefix && (<><dt>Default Path Prefix</dt><dd>{default_path_prefix}</dd></>)}
        </dl>
        <Link
          className='btn btn-outline-primary me-2'
          to={`/admin/domains/${domainId}/edit`}
        >
          Edit Domain
        </Link>
        {domain_settings
        ?
         (
           <Link
             className='btn btn-outline-dark me-2'
             to={`/admin/settings/${domain_settings.id}/edit`}
           >
             Edit Settings
           </Link>
         )
        :
         (
           <Link
             className='btn btn-outline-secondary me-2'
             to={`/admin/domains/${domainId}/settings/new`}
           >
             New Settings
           </Link>
         )
        }
        {ci_webhook_url && (
          <button
            className='btn btn-outline-dark me-2'
            onClick={() => {
              triggerCi()
            }}
          >
            Trigger Deployment
          </button>
        )}
      </section>
      <section>
        <h2>ClientToken</h2>
        <Table className='table-responsive mb-5'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Value</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {renderTokenRows}
          </tbody>
        </Table>
        <button
          className='btn btn-outline-secondary me-2'
          onClick={() => navigate(`/admin/domains/${domainId}/client_tokens/new`)}
        >
          New Token
        </button>
      </section>
    </>
  )
}

export default AdminDomain
