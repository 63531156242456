import React from 'react'

import {useNavigate} from 'react-router-dom'

import ComponentForm from './ComponentForm'

const ComponentNew = () => {
  const initialProps = {
    name: '',
    display_name: '',
    description: '',
    schema: `[
{
  "name": "my_string_field",
  "type": "string",
  "description": "A simple text field",
  "default": "demo"
},
{
  "name": "my_bool_field",
  "type": "boolean",
  "description": "A simple boolean field",
  "default": false
},
{
  "name": "an_enum_field",
  "type": "string",
  "enum": [
    "OneValue",
    "SecondValue",
    "TheThirdValue"
  ],
  "description": "A text field with explicitly allowed values",
  "default": "SecondValue"
}
]`
  }
  const navigate = useNavigate()

  return (
    <>
      <h1>New Component</h1>
      <ComponentForm
        {...initialProps}
        onSuccess={() => {
          navigate("../")
        }}
        onCancel={() => {
          navigate("../")
        }}
      />
    </>
  )
}

export default ComponentNew
