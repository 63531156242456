import React, {useState, useContext} from 'react'

import {Spinner} from 'react-bootstrap'
import {useQuery} from 'react-query'
import ImageLibraryApiContext from '../../ImageLibraryApiContext'
import ImagePickerTable from './Table'
import ImagePickerCards from './Cards'
import ImagePickerDetails from './Details'
import ImagePickerForm from './Form'
import ImagePickerCard from './Card'
import ImagePickerRow from './Row'

const imagePickerVariants = {
  'cards': ImagePickerCards,
  'table': ImagePickerTable
}

const imagePickerChildVariants = {
  'cards': ImagePickerCard,
  'table': ImagePickerRow
}

const hasCategory = ({category}) => !!category
const localeAwareSorter = (a, b) => a.localeCompare(b)

const ImagePickerBrowser = ({apiUrl, apiToken, client, onSelectImage}) => {
  const {imageLibraryClient} = useContext(ImageLibraryApiContext)
  const [imageId, setImageId] = useState()
  const [displayMode, setDisplayMode] = useState('cards')
  const [categoryFilter, setCategoryFilter] = useState()
  const [sortOrder, setSortOrder] = useState('id')
  const [mode, setMode] = useState('browse')

  const {isError, isLoading, error, data} = useQuery(['images'], () => {
    return imageLibraryClient.getImages()
  })

  const onEdit = (imageId) => {
    setImageId(imageId)
    setMode('edit')
  }

  const onShow = (imageId) => {
    setImageId(imageId)
    setMode('show')
  }

  const onNew = () => {
    setMode('new')
  }

  const onBrowse = () => {
    setImageId(null)
    setMode('browse')
  }

  const toggleDisplayMode = () => {
    setDisplayMode(displayMode === 'cards' ? 'table': 'cards')
  }

  const currentImage = () => {
    return data.find((img) => {
      return img.id === imageId
    })
  }

  const ImagePickerDisplay = imagePickerVariants[displayMode]
  const ImagePickerChildDisplay = imagePickerChildVariants[displayMode]

  if (isError) {
    return (
      <p className='text-danger text-center'>{error.message}</p>
    )
  }

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  const imageCategories = [...new Set(data.filter(hasCategory).map(({category}) => {
    return category
  }))].sort(localeAwareSorter)

  const imageCategoryOptions = imageCategories.map((category) => (
    <option value={category} key={category}>
      {category}
    </option>
  ))

  const filteredImages = data.filter((img) => {
    if (categoryFilter) {
      return img.category === categoryFilter
    } else {
      return true
    }
  })

  const displayableImages = filteredImages.sort((a, b) => {
    return a[sortOrder].toString().localeCompare(b[sortOrder].toString())
  })

  let renderable

  switch (mode) {
    case 'edit':
      renderable = (
        <ImagePickerForm
          onSuccess={onBrowse}
          onCancel={onBrowse}
          categoryOptions={imageCategoryOptions}
          {...currentImage()}
        />
      )
      break
    case 'show':
      renderable = (
        <ImagePickerDetails
          {...currentImage()}
        >
          <button
            type='button'
            className='btn btn-outline-primary me-2'
            onClick={() => onSelectImage(currentImage())}
          >
            Select
          </button>
          <button
            type='button'
            className='btn btn-outline-warning me-2'
            onClick={() => onEdit(currentImage().id)}
          >
            Edit
          </button>
          <button
            type='button'
            className='btn btn-outline-light'
            onClick={onBrowse}
          >
            Back
          </button>
        </ImagePickerDetails>
      )
      break
    case 'new':
      renderable = (
        <ImagePickerForm
          onSuccess={onBrowse}
          onCancel={onBrowse}
          categoryOptions={imageCategoryOptions}
          alt=''
          copyright=''
          category=''
          notes=''
        />
      )
      break
    case 'browse':
    default:
      renderable = (
        <>
          <div className='row row-cols-lg-auto align-items-center mb-3'>
            <div className='col-12'>
              <button
                type='button'
                onClick={toggleDisplayMode}
                className='btn btn-sm btn-outline-light'
              >
                {displayMode === 'cards' ? 'List as Table' : 'List as Cards'}
              </button>
            </div>
            <div className='col-12 d-flex flex-row align-items-center'>
              <label className='d-inline-block text-nowrap me-2'>
                Filter by Category
              </label>
              <select
                className='form-select form-select-sm'
                onChange={(event) => { setCategoryFilter(event.target.value) }}
              >
                <option value=''>All Categories</option>
                {imageCategoryOptions}
              </select>
            </div>
            <div className='col-12 d-flex flex-row align-items-center'>
              <label className='d-inline-block text-nowrap me-2'>
                Sort by
              </label>
              <select
                className='form-select form-select-sm'
                onChange={(event) => { setSortOrder(event.target.value) }}
                defaultValue='id'
              >
                <option value='id'>Id</option>
                <option value='category'>Category</option>
                <option value='alt'>Alt Text</option>
                <option value='copyright'>Copyright</option>
              </select>
            </div>
          </div>
          <ImagePickerDisplay data={displayableImages}>
            {(img) => (
              <ImagePickerChildDisplay
                {...img}
                key={img.id}
                preview={img.id === imageId}
                onClick={() => { setImageId(img.id) }}
              >
                <button
                  type='button'
                  onClick={() => onSelectImage(img)}
                  className='btn btn-outline-primary'
                >
                  Select
                </button>
                <button
                  type='button'
                  onClick={() => onShow(img.id)}
                  className='btn btn-outline-info'
                >
                  Show
                </button>
                <button
                  type='button'
                  onClick={() => onEdit(img.id)}
                  className='btn btn-outline-warning'
                >
                  Edit
                </button>
              </ImagePickerChildDisplay>
            )}
          </ImagePickerDisplay>
          <button
            onClick={onNew}
            className='btn btn-outline-success'
          >
            Upload new Image
          </button>
        </>
      )
  }

  return (
    <>
      {renderable}
    </>
  )
}

export default ImagePickerBrowser
