import React from 'react'

import {useNavigate} from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import ComponentForm from './ComponentForm'

import useComponent from '../hooks/useComponent'

const ComponentEdit = () => {
  const navigate = useNavigate()
  const {isLoading, isError, error, data} = useComponent()

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (isError) {
    return (<p className='text-danger text-center'>{error.message}</p>)
  }

  return (
    <>
      <h2>Edit Component</h2>
      <ComponentForm
        {...data}
        onSuccess={() => {
          navigate("../")
        }}
        onCancel={() => {
          navigate("../")
        }}
      />
    </>
  )
}

export default ComponentEdit
