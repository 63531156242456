import React, {useContext, useState} from 'react'
import {useQueryClient, useMutation} from 'react-query'
import {useParams, Link} from 'react-router-dom'
import {Table, Toast, ToastContainer, Card, Spinner}  from 'react-bootstrap'

import useDomain from '../hooks/useDomain'
import useRefreshAllImageUrls from '../hooks/useRefreshAllImageUrls'
import ApiContext from '../ApiContext'
import NewPageForm from './NewPageForm'

const sorter = {
  id: (a, b) => {
    return b.id - a.id
  },
  headline:  ({headline: aHeadline}, {headline: bHeadline}) => {
    return aHeadline.localeCompare(bHeadline)
  },
  slug: ({slug: aSlug}, {slug: bSlug}) => {
    return aSlug.localeCompare(bSlug)
  }
}

const Domain = () => {
  const [showsCiTriggered, setShowsCiTriggered] = useState(false)
  const [showsRefreshingUrls, setShowsRefreshingUrls] = useState(false)
  const [sortPagesBy, setSortPagesBy] = useState('id')
  const [pageFilterText, setPageFilterText] = useState('')
  const [pageFilterLayout, setPageFilterLayout] = useState()
  const {domainId} = useParams()
  const {client} = useContext(ApiContext)
  const {isLoading, isError, error, data: domain} = useDomain()
  const [newPage, setNewPage] = useState(false)
  const refreshAllImageUrls = useRefreshAllImageUrls()
  const queryClient = useQueryClient()


  const destroyPage = useMutation((pageId) => {
    return client.destroyPage(domainId, pageId)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        ['domains', domainId]
      )
    }
  })

  const triggerCi = async () => {
    await client.triggerCi(domainId)

    setShowsCiTriggered(true)
  }

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (isError) {
    return (<p className='text-danger text-center'>{error.message}</p>)
  }

  const confirmDestroy = (page) => {
    return window.confirm(`Do you really want to destroy ${page.headline ? page.headline : 'this page'}?`)
  }

  const {
    id,
    fqdn,
    slug,
    start_page,
    description,
    default_path_prefix,
    footer_text,
    meta_data,
    pages,
    ci_webhook_url,
  } = domain


  const handleRefreshAllImageUrls = async () => {
    // show toast on start
    setShowsRefreshingUrls(true)
    await refreshAllImageUrls(domain)
    queryClient.invalidateQueries(['domain', domainId])
    setShowsRefreshingUrls(false)
    // show toast on end
  }

  let startPageLink = (<span className='text-muted'>No Start Page yet</span>)

  if (start_page) {
    startPageLink = (
      <Link to={`/domains/${domainId}/pages/${start_page.id}`}>
        {start_page.headline}
      </Link>
    )
  }

  const hasPagePathFilter = pageFilterText !== ''
  const hasPageLayoutFilter = !!pageFilterLayout
  const isFiltered = hasPageLayoutFilter || hasPagePathFilter

  const filterByPath = ({path, headline}) => {
    if (!hasPagePathFilter) {
      return true
    }

    const matchesPath = path.toLowerCase().includes(pageFilterText.toLowerCase())
    const matchesHeadline = headline.toLowerCase().includes(pageFilterText.toLowerCase())
    return matchesPath || matchesHeadline
  }

  const filterByLayout = ({ layout }) => {
    if (!hasPageLayoutFilter) {
      return true
    }

    return layout === pageFilterLayout
  }

  const filteredPages = pages.filter(filterByPath).filter(filterByLayout)

  const layouts = [...new Set(pages.map(({ layout }) => layout))]

  return (
    <>
      <ToastContainer position='top-end' className='p-3'>
        <Toast show={showsCiTriggered} onClose={() => setShowsCiTriggered(false)}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">CI Deployment</strong>
          </Toast.Header>
          <Toast.Body>Deployment of {fqdn} successfully triggered!</Toast.Body>
        </Toast>
        <Toast show={showsRefreshingUrls}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Refreshing image URLs</strong>
          </Toast.Header>
          <Toast.Body>
            Refreshing all Image URLs for current DomainSettings.
            Please don't change Pages right now!
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <section className='mb-4'>
        <h1>{fqdn}</h1>
        {description && (
          <p>{description}</p>
        )}
        <div className='row row-cols-lg-3'>
          <div className='col mb-4'>
            <Card className='h-100'>
              <Card.Body>
                <dl>
                  <dt>ID</dt><dd>{id}</dd>
                  <dt>Domain name</dt><dd>{fqdn}</dd>
                  <dt>Slug</dt><dd>{slug}</dd>
                  {default_path_prefix && (<><dt>Default Path Prefix</dt><dd>{default_path_prefix}</dd></>)}
                </dl>
              </Card.Body>
            </Card>
          </div>
          <div className='col mb-4'>
            <Card className='h-100'>
              <Card.Body>
                <dl>
                  <dt>Start Page</dt><dd>{startPageLink}</dd>
                  <dt>Menus</dt>
                  <dd><Link to={`/domains/${domainId}/menus`}>Menus</Link></dd>
                  <dt>Display Modes</dt>
                  <dd><Link to={`/domains/${domainId}/display_modes`}>Usage Stats</Link></dd>
                  {footer_text && (<><dt>Footer Text</dt><dd>{footer_text}</dd></>)}
                </dl>
              </Card.Body>
            </Card>
          </div>
          <div className='col mb-4'>
            <Card className='h-100'>
              <Card.Body>
                <dl>
                  <dt>Meta Title</dt>
                  <dd>{meta_data.meta_title}</dd>
                  <dt>Meta Keywords</dt>
                  <dd>{meta_data.meta_keywords}</dd>
                  <dt>Meta Description</dt>
                  <dd>{meta_data.meta_description}</dd>
                </dl>
              </Card.Body>
            </Card>
          </div>
        </div>
        <Link
          className='btn btn-outline-primary me-2'
          to={`/domains/${domainId}/edit`}
        >
          Edit Domain
        </Link>
        <button
          className='btn btn-outline-success me-5'
          type='button'
          disabled={newPage}
          onClick={() => setNewPage(true)}
        >
          New Page
        </button>

        {ci_webhook_url && (
          <button
            className='btn btn-outline-dark me-2'
            onClick={() => {
              triggerCi()
            }}
          >
            Trigger Deployment
          </button>
        )}
        {(
          <button onClick={handleRefreshAllImageUrls} className='btn btn-outline-dark me-2'>
            Refresh Image URLs
          </button>
        )}
      </section>
      <section>
        <h2>{pages.length} Pages
          {isFiltered &&
           (<>
             {' '}<small className='text-body-secondary'>
             (showing {filteredPages.length})
             </small>
           </>
          )}
        </h2>
        {newPage && (
          <section className='mb-4'>
            <NewPageForm
              onSuccess={() => setNewPage(false)}
              onCancel={() => setNewPage(false)}
            />
          </section>
        )}
        <div className='row row-cols-lg-auto align-items-center'>
          <div className='col-12'>
            <label className='form-label mb-0'>
              Sort by:
            </label>
          </div>
          <div className='col-12'>
            <div className='form-check'>
              <input
                type='radio'
                name='sort-pages-by'
                id='sort-by-id'
                onInput={() => setSortPagesBy('id')}
                className='form-check-input'
              />
              <label
                htmlFor='sort-by-id'
                className='form-check-label'
              >
                ID
              </label>
            </div>
          </div>

          <div className='col-12'>
            <div className='form-check'>
              <input
                type='radio'
                name='sort-pages-by'
                id='sort-by-headline'
                onInput={() => setSortPagesBy('headline')}
                className='form-check-input'
              />
              <label
                htmlFor='sort-by-headline'
                className='form-check-label'
              >
                Name
              </label>
            </div>
          </div>
          <div className='col-12'>
            <div className='form-check'>
              <input
                className='form-check-input'
                type='radio'
                name='sort-pages-by'
                id='sort-by-path'
                onInput={() => setSortPagesBy('path')}
              />
              <label
                htmlFor='sort-by-path'
                className='form-check-label'
              >
                Path
              </label>
            </div>
          </div>
          <div className='col-12'>
            <input
              name='page-filter-text'
              type='text'
              placeholder='Filter Headline/Path'
              className='form-control'
              onInput={({target: { value }}) => setPageFilterText(value)}
            />
          </div>
          <div className='col-12'>
            <select
              name='page-filter-layout'
              className='form-select'
              onInput={({target: { value }}) => setPageFilterLayout(value)}
            >
              <option value=''>-- Filter Layout --</option>
              {layouts.map(layout => (<option key={layout} value={layout}>{layout}</option>))}
              </select>
          </div>
        </div>
        <Table className='table-responsive mb-5'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Path</th>
              <th colSpan='3'></th>
            </tr>
          </thead>
          <tbody>
            {filteredPages.sort(sorter[sortPagesBy]).map((page) => (
              <tr key={page.id}>
                <td>{page.id}</td>
                <td>{page.headline}</td>
                <td>{page.path}</td>
                <td className='text-end'>
                  <Link
                    className='btn btn-sm btn-outline-secondary'
                    to={`/domains/${domainId}/pages/${page.id}`}
                  >
                    Show
                  </Link>
                </td>
                <td>
                  <Link
                    className='btn btn-sm btn-outline-primary'
                    to={`/domains/${domainId}/pages/${page.id}/change`}
                  >
                    Change
                  </Link>
                </td>
                <td>
                  <button
                    className='btn btn-sm btn-outline-danger'
                    onClick={() => confirmDestroy(page) && destroyPage.mutate(page.id)}
                  >
                    Destroy
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </section>
    </>
  )
}

export default Domain
