import React, {useContext, useState} from 'react'

import {useParams, Link} from 'react-router-dom'

import {useQueryClient, useMutation} from 'react-query'

import {ApiContext} from '../ApiContext'

import SectionForm from './SectionForm'
import PageForm from './PageForm'
import EditableSection from './EditableSection'
import DomainContext from '../DomainContext'
import { makePageUtils } from '../utils'

const EditablePage = ({ onFinish, ...page }) => {
  const queryClient = useQueryClient()

  const [mode, setMode] = useState(null)
  const [edit, setEdit] = useState(false)

  const {domainId, pageId} = useParams()

  const {client} = useContext(ApiContext)
  const {domain} = useContext(DomainContext)
  const {showsField} = makePageUtils(domain)

  const moveSectionHigher = useMutation(section => {
    return client.moveSectionHigher(domainId, section.id)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        ['domains', domainId, 'pages', pageId]
      )
    }
  })

  const moveSectionLower = useMutation(section => {
    return client.moveSectionLower(domainId, section.id)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        ['domains', domainId, 'pages', pageId]
      )
    }
  })

  const canMoveSectionHigher = (index) => {
    return (page.sections.length > 1) && (index > 0)
  }

  const canMoveSectionLower = (index) => {
    return (page.sections.length > 1) && ((index + 1) < page.sections.length)
  }

  const destroySection = useMutation((sectionId) => {
    return client.destroySection(domainId, sectionId)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        ['domains', domainId, 'pages', pageId]
      )
    }
  })

  const confirmDestroy = (section) => {
    return window.confirm(`Do you really want to destroy ${section.headline ? section.headline : 'this section'}?`)
  }

  const newSection = () => {
    setMode('newSection')
    setEdit(true)
  }

  const editPage = () => {
    if (edit) {
      return
    }

    setMode('editPage')
    setEdit(true)
  }

  const closeForm = () => {
    setEdit(false)
    setMode(null)
  }

  const {headline, subline, lead_text, sections, path, layout, image_url} = page

  const renderableSections = (
    <>
      {sections.map((section, index) => (
        <div className='' key={section.id}>
          <EditableSection
            {...section}
            edit={edit}
            hasNext={(index +1) < sections.length}
            hasPrevious={index > 0}
            setEdit={setEdit}
          >
            <button
              onClick={() => confirmDestroy(section) && destroySection.mutate(section.id)}
              disabled={edit}
              className='btn btn-outline-danger btn-sm me-2'
            >
              Destroy Section
            </button>
            {canMoveSectionHigher(index) && (
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm me-2'
                onClick={() => { moveSectionHigher.mutate({ id: section.id }) }}
                disabled={edit}
              >
                Move Up
              </button>
            )}
            {canMoveSectionLower(index) && (
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm'
                onClick={() => { moveSectionLower.mutate({ id: section.id }) }}
                disabled={edit}
              >
                Move Down
              </button>
            )}
          </EditableSection>
        </div>
      ))}
    </>
  )

  const pageContent = (
    <>
      <Link
        className='btn btn-outline-dark mb-5'
        to={`/domains/${domainId}/pages/${pageId}`}
        disabled={edit}
      >
        Show Page
      </Link>
      <div className='ak-page-content'>
        {showsField(layout, 'image') && image_url && (
          <img src={image_url} className='img-fluid mb-2' alt='' />
        )}
        <div className={edit ? 'is-locked' : 'is-editable'}>
          <small className='hstack gap-2'>
            <code className='text-muted'>{path}</code>
            <div className='vr'/>
            <code className='text-muted'>layout: {layout || 'Default'}</code>
          </small>

          <strong className='mb-4'>{headline}{showsField(layout, 'subline') && subline && (
              <>
                <br/>
                <small className='text-muted'>{subline}</small>
              </>
          )}
          </strong>
          {showsField(layout, 'lead_text') && lead_text && (<p className='lead'>
            {lead_text}
          </p>)}
        </div>
        <div className='ak-actions-page ak-actions'>
          <button
            className='btn btn-sm btn-outline-primary me-2'
            type='button'
            onClick={editPage}
            disabled={edit}
          >
            Edit Page
          </button>
          <button
            onClick={newSection}
            className='btn btn-sm btn-outline-success'
            disabled={edit}
          >
            New Section
          </button>
        </div>
      </div>
    </>
  )

  return (<>
    <section className='mb-4 ak-page ak-page-editable'>
      {
        mode === 'editPage'
        ? (
          <PageForm
            onSuccess={closeForm}
            onCancel={closeForm}
          />
        )
        : pageContent
      }
      {renderableSections}
      {mode === 'newSection' && (
        <SectionForm
          onSuccess={closeForm}
          onCancel={closeForm}
          headline=''
          lead_text=''
          body=''
          page_id={parseInt(pageId, 10)}
          published={false}
        />
      )}
    </section>
  </>)
}


export default EditablePage
