import React from 'react'
import PropTypes from 'prop-types'

const ImagePickerTable = ({ data, children }) => {

  return (
    <table
      className='table table-striped table-dark'
    >
      <thead>
        <tr>
          <th>Thumb</th>
          <th>Category</th>
          <th>Alt Text</th>
          <th>Copyright</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {data.map((img) => children(img))}
      </tbody>
    </table>
  )
}

ImagePickerTable.propTypes = {
  data: PropTypes.array.isRequired,
  children: PropTypes.func.isRequired,
}

export default ImagePickerTable
