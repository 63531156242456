import React, {useContext, useState} from 'react'
import {Spinner} from 'react-bootstrap'
import {useQuery, useQueryClient, useMutation} from 'react-query'
import ApiContext from '../ApiContext'
import {Link, useParams} from 'react-router-dom'
import MenuForm from './MenuForm'

const Menus = () => {
  const [showsNewForm, setShowsNewForm] = useState(false)
  const queryClient = useQueryClient()
  const {client} = useContext(ApiContext)
  const {domainId} = useParams()
  const {isLoading, isError, error, data} = useQuery(['domains', domainId, 'menus'], () => {
    return client.fetchMenus(domainId)
  })

  const destroyMenu = useMutation((id) => {
    return client.destroyMenu(domainId, id)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(['domains', domainId, 'menus'])
    }
  })

  const handleDestroy = (id) => {
    if (window.confirm(`Are you sure you want to delete menu ${id}?`)) {
      destroyMenu.mutate(id)
    }
  }

  const openNewForm = () => {
    setShowsNewForm(true)
  }

  const closeNewForm = () => {
    setShowsNewForm(false)
  }

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (isError) {
    return (<p className='text-danger text-center'>Error {error.message}</p>)
  }

  return (
    <>
      <h1>All Menus for Domain</h1>
      <table className='table'>
        <thead>
          <tr>
            <th>Display Name</th>
            <th>Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map(({id, name, display_name}) => (
            <tr key={id}>
              <td>{display_name}</td>
              <td>{name}</td>
              <td>
                <Link
                  to={`/domains/${domainId}/menus/${id}`}
                  className='btn btn-sm btn-outline-secondary me-2'
                >
                  Show
                </Link>
                <Link
                  to={`/domains/${domainId}/menus/${id}/edit`}
                  className='btn btn-sm btn-outline-primary me-2'
                >
                  Edit Menu
                </Link>
                <button
                  type='button'
                  className='btn btn-sm btn-outline-danger'
                  onClick={() => handleDestroy(id)}
                >
                  Destroy Menu
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showsNewForm
      ?
       (
         <MenuForm
           onSuccess={closeNewForm}
           onCancel={closeNewForm}
           domain_id={parseInt(domainId, 10)}
           display_name=''
           name=''
           entries={[]}
         />
       )
      :
       (
         <button
           type='button'
           className='btn btn-outline-primary'
           onClick={openNewForm}
         >
           New Menu
         </button>
       )
      }
    </>
  )
}

export default Menus
