import React from 'react'
import {Field} from 'formik'

const TextOrEnumField = ({className, ...field}) => {
  if (field.enum) {
    return (
      <Field
        name={field.name}
        as='select'
        className='form-select'
      >
        <option key='___blank_option' value=''>-- Default --</option>
        {field.enum.map((option) => (
          <option key={option} value={option}>{option}</option>
        ))}
      </Field>
    )
  } else {
    return (
      <Field
        name={field.name}
        type='text'
        className={className}
      />
    )
  }
}

export default TextOrEnumField
