import React, {useContext, useEffect, useRef} from 'react'

import {Spinner, Form as BSForm} from 'react-bootstrap'
import {useParams} from 'react-router-dom'
import {Formik, Form, Field} from 'formik'
import {useQueryClient, useMutation} from 'react-query'

import {ApiContext} from '../ApiContext'
import {MessagesContext} from '../MessagesContext'
import LayoutInfo from './LayoutInfo'
import useDomain from '../hooks/useDomain'

const NewPageForm = ({onSuccess, onCancel}) => {
  const ref = useRef()
  const queryClient = useQueryClient()
  const {addMessage} = useContext(MessagesContext)

  const {domainId} = useParams()
  const {client} = useContext(ApiContext)

  const createPage = useMutation(page => {
    return client.createPage(domainId, page)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        ['domains', domainId]
      )
    }
  })

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollIntoView()
    }
  }, [])

  const domainQuery =  useDomain()

  if (domainQuery.isLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (domainQuery.isError) {
    return (<p className='text-danger text-danger'>{domainQuery.error.message}</p>)
  }

  const domainSettings = domainQuery.data.domain_settings
  const layoutOptions = domainSettings.layouts.map((m,i) => {
    return(
      <option key={'layout-' + i} value={m['name']}>{m['name']}</option>
    )
  })

  return (
    <div className='bg-warning bg-opacity-10 p-3' ref={ref}>
      <h2>New Page</h2>
      <Formik
        initialValues={{
          headline: '',
          layout: 'default',
        }}
        onSubmit={(values, formikBag) => {
          values.breadcrumbs = []
          createPage.mutate(values, {
            onSuccess: () => {
              formikBag.setSubmitting(false)
              onSuccess()
            },
            onError: (err) => {
              addMessage(`Cannot create Page: ${err.message}`, 'error')
              formikBag.setSubmitting(false)
            }
          })
        }}
      >
        {({isSubmitting}) => (
          <Form className='vstack gap-3'>
            <div className='form-group'>
              <label>Name</label>
              <Field
                type='text'
                name='headline'
                autoFocus={true}
                className='form-control'
                aria-describedby="headlineHelpBlock"
              />
              <BSForm.Text id="headlineHelpBlock" muted>
                Used to generate <code>path</code>. Some domains may use this name as the page
                headline.
              </BSForm.Text>
            </div>

            <div className='form-group'>
              <label className='form-label'>
                Layout
                <LayoutInfo.Trigger domainSettings={domainSettings} />
              </label>
              <Field
                as='select'
                name='layout'
                className='form-select'
              >
                <option>--Select Layout--</option>
                { layoutOptions }
              </Field>
            </div>

            <div className='hstack gap-2'>
              <button
                type='submit'
                disabled={isSubmitting}
                className='btn btn-outline-primary'
              >
                Save
              </button>
              <button
                type='button'
                disabled={isSubmitting}
                onClick={onCancel}
                className='btn btn-outline-secondary'
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default NewPageForm
