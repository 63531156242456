import React, {useContext} from 'react'

import {useQueryClient, useMutation} from 'react-query'
import {Formik, Form, Field} from 'formik'

import {ApiContext} from '../../ApiContext'
import {MessagesContext} from '../../MessagesContext'

const DomainForm = ({onSuccess, onCancel, id, ...props}) => {
  const queryClient = useQueryClient()
  const {client} = useContext(ApiContext)
  const {addMessage} = useContext(MessagesContext)
  
  const createOrUpdateDomain = useMutation(domain => {
    if (id) {
      return client.updateDomain(id, domain)
    } else {
      return client.createDomain(domain)
    }
  },{
    onSuccess: () => {
      queryClient.invalidateQueries(['adminDomains'])
    }
  })

  const {
    fqdn,
    slug,
    default_path_prefix,
    ci_user,
    ci_user_token,
    ci_webhook_url,
    ci_webhook_token,
  } = props

  return (
    <>
      <Formik
        initialValues={{
          fqdn,
          slug,
          default_path_prefix,
          ci_user: ci_user || '',
          ci_user_token: ci_user_token || '',
          ci_webhook_url: ci_webhook_url || '',
          ci_webhook_token: ci_webhook_token || '',
        }}
        onSubmit={(values, formikBag) => {
          createOrUpdateDomain.mutate(values, {
            onSuccess: () => {
              formikBag.setSubmitting(false)
              onSuccess()
            },
            onError: (err) => {
              addMessage(`Cannot save Domain: ${err.message}`, 'error')
              formikBag.setSubmitting(false)
            }
          })
        }}
      >
        {({values, isSubmitting}) => (
          <Form>
            <div className='form-group mb-3'>
              <label className='form-label'>FQDN</label>
              <Field type='text' name='fqdn' className='form-control' />
            </div>
            <div className='form-group mb-3'>
              <label className='form-label'>Slug</label>
              <Field type='text' name='slug' className='form-control' />
            </div>
            <div className='form-group mb-3'>
              <label className='form-label'>Default Path Prefix</label>
              <Field type='text' name='default_path_prefix' className='form-control' />
            </div>
            <div className='form-group mb-3'>
              <label className='form-label'>CI User</label>
              <Field type='text' name='ci_user' className='form-control' />
              <div className='form-text'>
                Jenkins deployment User
              </div>
            </div>
            <div className='form-group mb-3'>
              <label className='form-label'>CI User Token</label>
              <Field type='text' name='ci_user_token' className='form-control' />
              <div className='form-text'>
                Authentication token triggering deployment as CI User
              </div>
            </div>
            <div className='form-group mb-3'>
              <label className='form-label'>CI Webhook URL</label>
              <Field type='text' name='ci_webhook_url' className='form-control' />
              <div className='form-text'>
                URL to ping for deployment,
                e.g.: <code>
                https://ci.austauschkompass.net/job/testproject/build
                </code> (setup as a Jenkins Project <em>Build Trigger</em>)
              </div>
            </div>
            <div className='form-group mb-3'>
              <label className='form-label'>CI Webhook Token</label>
              <Field type='text' name='ci_webhook_token' className='form-control' />
              <div className='form-text'>
                Jenkins Project Build Trigger <em>Authentication Token</em>
                used to authenticate deployment API-Call against CI Webhook URL
              </div>
            </div>
            <button
              type='submit'
              disabled={isSubmitting}
              className='btn btn-outline-primary me-2'
              >
              Save
            </button>
            <button
              type='button'
              disabled={isSubmitting}
              onClick={onCancel}
              className='btn btn-outline-secondary'>
              Cancel
            </button>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default DomainForm
