import React from 'react'

import {useNavigate} from 'react-router-dom'

import SettingForm from './SettingForm'

const SettingNew = () => {
  const initialProps = {
    section_display_modes: JSON.stringify([]),
    item_display_modes: JSON.stringify([]),
    layouts: JSON.stringify([
      {
        name: 'simple',
        is_default: true,
        page_image_config: {
          width: 320,
          height: 240
        },
        section_image_config: {
          width: 320,
          height: 240
        },
        item_image_config: {
          width: 320,
          height: 240
        }
    }], null, "  ")
  }
  
  const navigate = useNavigate()
  
  return (
    <>
      <h1>New Setting</h1>
      <SettingForm
        {...initialProps}
        onSuccess={() => {
          navigate("../")
        }}
        onCancel={() => {
          navigate("../")
        }}
      />
    </>
  )
}

export default  SettingNew
