import React from 'react'
import PropTypes from 'prop-types'
import {Routes, Route, Outlet, NavLink} from 'react-router-dom'

import RequireApi from './components/RequireApi'
import RequireApiAndDomain from './components/RequireApiAndDomain'
import Navigation from './components/Navigation'
import UserNavLinks from './components/Navigation/UserNavLinks'
import GuestNavLinks from './components/Navigation/GuestNavLinks'
import DomainLink from './components/Navigation/DomainLink'
import Login from './components/Login'
import AuthCallback from './components/AuthCallback'
import Home from './components/Home'
import Domain from './components/Domain'
import DomainForm from './components/DomainForm'
import Domains from './components/Domains'
import Users from './components/Users'
import User from './components/User'
import UserEdit from './components/UserEdit'
import Components from './components/Components'
import Component from './components/Component'
import ComponentNew from './components/ComponentNew'
import ComponentEdit from './components/ComponentEdit'
import AdminDomainNew from './components/Admin/DomainNew'
import AdminDomainEdit from './components/Admin/DomainEdit'
import AdminDomains from './components/AdminDomains'
import AdminDomain from './components/AdminDomain'
import Page from './components/Page'
import RequireAdmin from './components/RequireAdmin'
import SettingNew from './components/SettingNew'
import SettingEdit from './components/SettingEdit'
import ClientTokenEdit from './components/ClientTokenEdit'
import ClientTokenNew from './components/ClientTokenNew'
import Menu from './components/Menu'
import MenuEdit from './components/MenuEdit'
import Menus from './components/Menus'
import DisplayModeUsage from './components/DisplayModeUsage'
import BreadcrumbsEdit from './components/BreadcrumbsEdit'
import Messages from './components/Messages'
//import {createHistory} from 'history'

import './AkCmsAdmin.scss'

const GuestLayout = () => {
  return (
    <>
      <Navigation>
        <GuestNavLinks/>
      </Navigation>
      <main className="container">
        <Messages />
        <Outlet/>
      </main>
    </>
  )
}

const AuthenticatedLayout = ({ apiUrl }) => {
  return (
    <RequireApi apiUrl={apiUrl}>
      <Navigation>
        <UserNavLinks>
        </UserNavLinks>
      </Navigation>
      <main className='container'>
        <Messages />
        <Outlet />
      </main>
    </RequireApi>
  )
}


const DomainLayout = ({apiUrl}) => {
  return (
    <RequireApiAndDomain apiUrl={apiUrl} >
      <Navigation>
        <UserNavLinks>
          <DomainLink as={NavLink}/>
        </UserNavLinks>
      </Navigation>
      <main className='container'>
        <Messages />
        <Outlet />
      </main>
    </RequireApiAndDomain>
  )
}

const AdminLayout = ({ apiUrl }) => {

  return (
    <RequireApi apiUrl={apiUrl}>
      <RequireAdmin>
        <Navigation>
          <UserNavLinks>
          </UserNavLinks>
        </Navigation>
        <main className='container'>
          <Messages />
          <Outlet />
        </main>
      </RequireAdmin>
    </RequireApi>
  )
}

const AkCmsAdmin = ({apiUrl})  => {

  return (
    <Routes>
      <Route path='/'>

        <Route element={<GuestLayout/>}>
          <Route index element={<Home />} />
          <Route path='login' element={<Login />} />
          <Route path='oauth/callback' element={<AuthCallback />} />
        </Route>

        <Route path='admin'  element={<AdminLayout apiUrl={apiUrl}/>}>

          <Route path='client_tokens/:tokenId/edit' element={<ClientTokenEdit />}/>
          <Route path='settings/:settingId/edit' element={<SettingEdit />}/>

          <Route path='users'>
            <Route index element={<Users />} />
            <Route path=':userId' element={<User />} />
            <Route path=':userId/edit' element={<UserEdit />} />
          </Route>

          <Route path='components'>
            <Route index element={<Components />} />
            <Route path='new' element={<ComponentNew />} />
            <Route path=':componentId' element={<Component />} />
            <Route path=':componentId/edit' element={<ComponentEdit />} />
          </Route>

          <Route path='domains'>
            <Route index element={<AdminDomains />} />
            <Route path='new' element={<AdminDomainNew />} />
            <Route path=':domainId' element={<AdminDomain />} />
            <Route path=':domainId/edit' element={<AdminDomainEdit />} />
            <Route path=':domainId/client_tokens/new' element={<ClientTokenNew />} />
            <Route path=':domainId/settings'>
              <Route path='new' element={<SettingNew />}/>
            </Route>
          </Route>
        </Route>

        <Route path='domains' element={<AuthenticatedLayout apiUrl={apiUrl}/>}>
          <Route index element={<Domains />}/>
        </Route>

        <Route path='/domains/:domainId' element={<DomainLayout apiUrl={apiUrl} />}>
          <Route index element={<Domain />} />
          <Route path='edit' element={<DomainForm />}/>

          <Route path='pages/:pageId'>
            <Route path='' element={<Page />} />
            <Route path='change' element={<Page editableMode={true} />} />
            <Route path='breadcrumbs/:breadcrumbsId'>
              <Route path='edit' element={<BreadcrumbsEdit />} />
            </Route>
          </Route>

          <Route path='menus' element={<Menus />} />
          <Route path='menus/:menuId' element={<Menu />} />
          <Route path='menus/:menuId/edit' element={<MenuEdit />} />

          <Route path='display_modes' element={<DisplayModeUsage />} />
        </Route>

      </Route>
    </Routes>
  )
}

AkCmsAdmin.propTypes = {
  apiUrl: PropTypes.string.isRequired
}

export default AkCmsAdmin
