import React from 'react'

import {Popover, OverlayTrigger} from 'react-bootstrap'

const LayoutInfo = {}

LayoutInfo.Popover = React.forwardRef(({ domainSettings, ...props }, ref) => (
  <Popover id='layout-info' ref={ref} {...props}>
    <Popover.Header as='h3'>Layout Info</Popover.Header>
    <Popover.Body>
      <p>
        Decide how this Page is shown on the website.
        Most of the time tells you how images are cropped/resized
        and its sections will be laid out.
      </p>
      <dl className='mb-0'>
        {domainSettings.layouts.map(({name, description, is_default}) => (
          <React.Fragment key={name}>
            <dt>{name}{is_default && ' (Default)'}</dt>
            <dd>{description}</dd>
          </React.Fragment>
        ))}
      </dl>
    </Popover.Body>
  </Popover>
))

LayoutInfo.Trigger = ({ domainSettings }) => (
  <OverlayTrigger
    trigger='click'
    placement='right'
    overlay={<LayoutInfo.Popover domainSettings={domainSettings} />}
    rootClose={true}
  >
    <span className='badge bg-dark rounded-pill ms-1'>?</span>
  </OverlayTrigger>
)

export default LayoutInfo
