import React, {useState, useContext, useRef} from 'react'

import {Spinner} from 'react-bootstrap'
import Markdown from './Markdown'
import ItemForm from './ItemForm'
import ApiContext from '../ApiContext'
import {useQuery} from 'react-query'
import {toSlug} from 'ak-react-utils'
import {makeItemUtils} from '../utils'
import DomainContext from '../DomainContext'

const EditableItem = ({edit, setEdit, ...props}) => {
  const [mode, setMode] = useState()
  const ref = useRef()
  const {client} = useContext(ApiContext)
  const {domain} = useContext(DomainContext)
  const {showsField} = makeItemUtils(domain)

  const {isLoading, isError, error, data: components} = useQuery(['components'], () => {
    return client.fetchComponents()
  })

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (isError) {
    return (
      <span className='text-danger text-center'>{error}</span>
    )
  }

  const editItem = () => {
    if (edit) {
      return
    }

    setEdit(true)
    setMode('editItem')
  }

  const closeForm = () => {
    setEdit(false)
    setMode()
    if (ref && ref.current) {
      ref.current.scrollIntoView()
    }
  }

  const displayMode = props.display_mode

  let component

  if (props.has_component) {
    component = components.find(({name}) => name === props.component_name)
  }

  if (mode === 'editItem') {
    return (
      <div ref={ref}>
        <ItemForm
          onSuccess={closeForm}
          onCancel={closeForm}
          {...props}
        />
      </div>
    )
  } else {

    return (
      <>
        <div
          ref={ref}
          className={`ak-item ak-item-editable ${props.published ? 'is-published' : 'is-unpublished'}`}
        >
          <div className='ak-item-content'>
            <div className={edit ? 'is-locked' : 'is-editable'}>
              {!props.published && (
                <div className='mb-2 small text-muted'>
                  (Unpublished)
                </div>
              )}
              <div className='small mb-2'>
                {showsField(displayMode, 'image') && props.image_url && (
                  <img src={props.image_url} className='img-fluid mb-2' alt='' />
                )}
                <small className='hstack gap-2'>
                  <code className='text-muted'>display mode: {displayMode || 'Default'}</code>
                  {showsField(displayMode, 'link') &&
                  props.target_page_path && (
                    <>
                      <div className='vr'/>
                      <code className='text-muted'>page: {props.target_page_path}</code>
                    </>
                  )}
                  {showsField(displayMode, 'link') && props.target_url && (
                    <>
                      <div className='vr'/>
                      <code className='text-muted'>external url: {props.target_url}</code>
                    </>
                  )}
                </small>
                {showsField(displayMode, 'headline') && (
                  <>
                    <h4>
                      {props.headline}
                    </h4>
                    <code className='text-muted'>{toSlug(props.headline)}</code>
                  </>
                )}

                {showsField(displayMode, 'intro') && props.intro && (
                  <div className='lead'>{props.intro}</div>
                )}
                {showsField(displayMode, 'body') && props.body && (
                  <Markdown>{props.body}</Markdown>
                )}
                {showsField(displayMode, 'component') && component && (
                  <div className='bg-dark p-3' style={{ '--bs-bg-opacity': 0.125 }}>
                    <h4>{component.display_name}</h4>
                    <code>{props.component_config}</code>
                  </div>
                )}
              </div>
            </div>
            <div className='ak-actions ak-actions-item'>
              <button
                type='button'
                className='btn btn-sm btn-outline-primary me-2'
                onClick={editItem}
                disabled={edit}
              >
                Edit Item
              </button>
              {props.children}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default EditableItem
