import {useContext} from 'react'
import ApiContext from '../ApiContext'
import {useParams} from 'react-router-dom'
import {useQuery} from 'react-query'


const useUser = () => {
  const {client} = useContext(ApiContext)
  const {userId} = useParams()
  return useQuery(['users', userId], () => client.fetchUser(userId))
}

export default useUser
