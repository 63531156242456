import React from 'react'

import {Spinner} from 'react-bootstrap'
import useDomain from '../hooks/useDomain'
import { DomainContextProvider } from '../DomainContext'

const RequireDomain = ({children}) => {
  const {isLoading, isError, error, data} = useDomain()

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (isError) {
    return (<p className='text-danger text-center'>{error.message}</p>)
  }

  return (
    <DomainContextProvider initialDomain={data}>{children}</DomainContextProvider>
  )
}

export default RequireDomain
