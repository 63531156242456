import React, {useContext} from 'react'
import {useQuery} from 'react-query'
import ApiContext from '../ApiContext'
import {Link} from 'react-router-dom'

import {Table, Spinner} from 'react-bootstrap'

const Domains = () => {
  const {client} = useContext(ApiContext)
  const {isLoading, isError, error, data} = useQuery(['domains'], client.fetchDomains)

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (isError) {
    return (<p className='text-danger text-center'>Error {error.message}</p>)
  }

  return (
    <>
      <h1>All Domains</h1>
      <Table responsive>
        <thead>
          <tr>
            <th>
              FQDN
            </th>
            <th>
              Slug
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map(({id, fqdn, slug}) => (
            <tr key={id}>
              <td>
                {fqdn}
              </td>
              <td>
                {slug}
              </td>
              <td className='text-end'>
                <Link
                  to={`/domains/${id}`}
                  className='btn btn-sm btn-outline-secondary me-2'
                >
                  Show
                </Link>
                <Link
                  to={`/domains/${id}/edit`}
                  className='btn btn-sm btn-outline-primary me-2'
                >
                  Edit Domain
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

export default Domains
