import {useContext} from 'react'

import ApiContext from '../ApiContext'
import {useParams} from 'react-router-dom'
import {useQuery} from 'react-query'

const useClientToken = () => {
  const {client} = useContext(ApiContext)
  const {tokenId} = useParams()
  return useQuery(['client_tokens', tokenId], () => client.fetchClientToken(tokenId))
}

export default useClientToken
