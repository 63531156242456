const defaultFor = (displayModesOrLayouts) => {
  const potentialDefault = displayModesOrLayouts.find(
    ({ is_default }) => is_default,
  )

  // In case this does not specify a default (yet)
  // just return the first
  return potentialDefault ? potentialDefault : displayModesOrLayouts[0]
}

const layoutConfigFor = (layout, domainSettings) => {
  const defaultLayoutConfig = domainSettings.layouts.find(({is_default}) => is_default)

  const layoutConfig = domainSettings.layouts.find(({name}) => {
    return layout === name
  }) || defaultLayoutConfig

  return layoutConfig
}

const imageConfigFor = (displayMode, availableDisplayModes, fallbackImageConfig) => {
  const displayModeConfig = availableDisplayModes.find(({ name }) => {
    return name === displayMode
  })

  if (!displayModeConfig) {
    return fallbackImageConfig
  }

  const displayModeImageConfig = displayModeConfig.image_config

  return displayModeImageConfig ? displayModeImageConfig : fallbackImageConfig
}


const defaultImgConfig = {
  resizingType: 'fill',
  width: 100,
  height: 100,
  gravity: 'sm',
  enlarge: 1,
  extension: 'webp',
}

const makeFieldsHelperFor = (displayModesOrLayouts, defaultDisplayModeOrLayout) => {
  const modeMap = Object.fromEntries(displayModesOrLayouts.map((mode) => [mode.name, mode]))
  const getMode = (mode) => {
    return modeMap[mode] ? modeMap[mode] : defaultDisplayModeOrLayout
  }

  const configuresFields = (displayMode) => {
    const modeSettings = getMode(displayMode)

    return Array.isArray(modeSettings.attributes)
  }

  const showsField = (displayMode, name) => {
    const modeSettings = getMode(displayMode)

    if (configuresFields(displayMode)) {
      const attributesToShow = modeSettings.attributes
      const shownFormFields = attributesToShow.map(attr => attr.name)

      return shownFormFields.includes(name)
    } else {
      return true
    }
  }

  const descriptionForMode = (modeName) => {
    const modeSettings = getMode(modeName)

    return modeSettings.description
  }

  // Fallback to default if modeName cannot be found
  // otherwise just pass value through
  const nameForMode = (modeName) => {
    const modeSettings = getMode(modeName)

    return modeSettings.name
  }

  return {
    nameForMode,
    descriptionForMode,
    configuresFields,
    showsField,
    defaultMode: defaultDisplayModeOrLayout,
  }
}

const makePageUtils = (domain) => {
  const domainSettings = domain.domain_settings
  const layouts = domainSettings.layouts
  const defaultLayout = defaultFor(layouts)

  return makeFieldsHelperFor(layouts, defaultLayout)
}

const makeItemUtils = (domain) => {
  const domainSettings = domain.domain_settings
  const itemDisplayModes = domainSettings.item_display_modes
  const defaultItemDisplayMode = defaultFor(itemDisplayModes)

  return makeFieldsHelperFor(itemDisplayModes, defaultItemDisplayMode)
}

const makeSectionUtils = (domain) => {
  const domainSettings = domain.domain_settings
  const sectionDisplayModes = domainSettings.section_display_modes
  const defaultSectionDisplayMode = defaultFor(sectionDisplayModes)

  return makeFieldsHelperFor(sectionDisplayModes, defaultSectionDisplayMode)
}

export {
  makeItemUtils,
  makeSectionUtils,
  makePageUtils,
  layoutConfigFor,
  imageConfigFor,
  defaultImgConfig,
}
