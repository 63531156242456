import React from 'react'

import {Spinner} from 'react-bootstrap'
import Nav from 'react-bootstrap/Nav'

import useDomain from '../../hooks/useDomain'

const DomainLink = ({as}) => {
  const {isLoading, isError, error, data} = useDomain()

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (isError) {
    return (<p className='text-danger text-center'>{error.message}</p>)
  }

  return (
    <Nav.Link
      as={as}
      to={`/domains/${data.id}`}>
      {data.fqdn}
    </Nav.Link>
  )
}

export default DomainLink
