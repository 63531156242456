import React, {useContext, useEffect} from 'react'
import {useLocation} from 'react-router-dom'

import AuthContext from '../AuthContext'

function Login() {
  const {beginLogin, isLoggedIn} = useContext(AuthContext)
  const location = useLocation()
  const originalUrl = location.state?.from?.pathname || "/"
  useEffect(() => {
    if (!isLoggedIn) {
      beginLogin({ originalUrl })
    }
  }, [isLoggedIn, beginLogin, originalUrl])

  if (isLoggedIn) {
    return (<>You are already logged in!</>)
  } else {
    return (<>Logging you in...</>)
  }
}

export default Login
