import React, {useRef, useContext, useState} from 'react'

import {useQueryClient, useMutation} from 'react-query'
import {useParams} from 'react-router-dom'

import {toSlug} from 'ak-react-utils'
import {makeSectionUtils} from '../utils'
import ApiContext from '../ApiContext'
import DomainContext from '../DomainContext'
import Markdown from './Markdown'
import EditableItem from './EditableItem'
import ItemForm from './ItemForm'
import SectionForm from './SectionForm'

const EditableSection = ({edit, hasNext, hasPrevious, setEdit, ...props}) => {
  const ref = useRef()
  const queryClient = useQueryClient()
  const [mode, setMode] = useState()
  const {domainId, pageId} = useParams()
  const {client} = useContext(ApiContext)
  const {domain} = useContext(DomainContext)
  const {showsField} = makeSectionUtils(domain)

  const destroyItem = useMutation((sectionId) => {
    return client.destroyItem(domainId, sectionId)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        ['domains', domainId, 'pages', pageId]
      )
    }
  })

  const moveItemHigher = useMutation(item => {
    return client.moveItemHigher(domainId, item.id)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        ['domains', domainId, 'pages', pageId]
      )
    }
  })

  const moveItemLower = useMutation(item => {
    return client.moveItemLower(domainId, item.id)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        ['domains', domainId, 'pages', pageId]
      )
    }
  })

  const hasManyItems = props.items.length > 1
  const isFirstItem = (index) => index === 0
  const isLastItem = (index) => (index + 1) === props.items.length

  const canMoveItemHigher = (index) => {
    return (hasManyItems && !isFirstItem(index)) || hasPrevious
  }

  const canMoveItemLower = (index) => {
    return (hasManyItems && !isLastItem(index)) || hasNext
  }

  const moveUpLabel = (index) => {
    return isFirstItem(index) ? 'Move to previous section' : 'Move up'
  }
  const moveDownLabel = (index) => {
    return isLastItem(index) ? 'Move to next section' : 'Move down'
  }

  const confirmDestroy = (item) => {
    return window.confirm(`Do you really want to destroy ${item.headline ? item.headline : 'this item'}?`)
  }

  const newItem = () => {
    setEdit(true)
    setMode('newItem')
  }

  const editSection = () => {
    if (edit) {
      return
    }

    setEdit(true)
    setMode('editSection')
  }

  const closeForm = () => {
    setEdit(false)
    setMode()
    if (ref && ref.current) {
      ref.current.scrollIntoView()
    }
  }

  const displayMode = props.display_mode

  const sectionContent = (
    <div className='ak-section-content'>
      <div className={edit ? 'is-locked' : 'is-editable'}>
        {!props.published &&
         (<div className='mb-2 small text-muted'>
           (Unpublished)
         </div>
        )}
        {showsField(displayMode, 'image') && props.image_url && (
          <img src={props.image_url} className='img-fluid mb-2' alt=''/>
        )}
        <div className='mb-3'>
          <small className='hstack gap-2'>
            {showsField(displayMode, 'headline') && (
              <>
                <code className='text-muted'>{toSlug(props.headline)}</code>
                <div className='vr'/>
              </>
            )}
            <code className='text-muted'>display mode: {props.display_mode || 'Default'}</code>
            {showsField(displayMode, 'link') && props.target_page_path && (
              <>
                <div className='vr'/>
                <code className='text-muted'>page: {props.target_page_path}</code></>
            )}
            {showsField(displayMode, 'link') && props.target_url && (
              <>
                <div className='vr'/>
                <code className='text-muted'>external url: {props.target_url}</code>
              </>
            )}
          </small>
          {showsField(displayMode, 'headline') && (
            <h3>
              {props.headline}
            </h3>
          )}
        </div>
        {showsField(displayMode, 'lead_text') && props.lead_text && (
          <div className='mb-3 small'>
            <p className='lead'>{props.lead_text}</p>
          </div>
        )}

        {showsField(displayMode, 'body') && props.body && (
          <div className='mb-3'>
            <Markdown>{props.body}</Markdown>
          </div>
        )}
      </div>

      <div className='ak-actions ak-actions-section mb-2'>
        <button
          onClick={editSection}
          disabled={edit}
          className='btn btn-outline-primary btn-sm me-2'
        >
          Edit Section
        </button>
        <button
          onClick={newItem}
          disabled={edit}
          className='btn btn-outline-success btn-sm me-2'
        >
          New Item
        </button>
        {props.children}
      </div>
    </div>
  )

  return (
    <>
      <section
        className={`ak-section ak-section-editable ${props.published ? 'is-published' : 'is-unpublished'}`}
        ref={ref}>
        {
          mode === 'editSection'
          ? (
            <div className='mb-3'>
              <SectionForm
                onSuccess={closeForm}
                onCancel={closeForm}
                {...props}
              />
            </div>
          )
          : sectionContent
        }
        {props.items.length > 0 && (
          <div>
            {props.items.map((item, index) => (
              <div key={item.id}>
                <EditableItem {...item} edit={edit} setEdit={setEdit}>
                  <button
                    type='button'
                    className='btn btn-sm btn-outline-danger me-2'
                    onClick={() => confirmDestroy(item) && destroyItem.mutate(item.id)}
                    disabled={edit}
                  >
                    Destroy Item
                  </button>
                  {canMoveItemHigher(index) && (
                    <button
                      type='button'
                      className='btn btn-sm btn-outline-secondary me-2'
                      onClick={() => moveItemHigher.mutate(item)}
                      disabled={edit}
                    >
                      {moveUpLabel(index)}
                    </button>
                  )}
                  {canMoveItemLower(index) && (
                    <button
                      type='button'
                      className='btn btn-sm btn-outline-secondary'
                      onClick={() => moveItemLower.mutate(item)}
                      disabled={edit}
                    >
                      {moveDownLabel(index)}
                    </button>
                  )}
                </EditableItem>
              </div>
            ))}
          </div>
        )}
        {mode === 'newItem' &&
         (
           <div className='mb-3'>
             <ItemForm
               onSuccess={closeForm}
               onCancel={closeForm}
               headline=''
               lead_text=''
               section_id={props.id}
               target_link_text=''
               published={false}
             />
           </div>
        )}
      </section>
    </>
  )
}

export default EditableSection
