import React, {useContext} from 'react'

import {dimensionsFromImageUrl} from 'ak-react-utils'

import {Spinner} from 'react-bootstrap'
import {toSlug} from 'ak-react-utils'
import Markdown from './Markdown'
import ApiContext from '../ApiContext'
import DomainContext from '../DomainContext'
import {makeItemUtils} from '../utils'
import {useQuery} from 'react-query'

const Item = (props) => {
  const {client} = useContext(ApiContext)
  const {domain} = useContext(DomainContext)
  const {showsField} = makeItemUtils(domain)

  const {isLoading, isError, error, data: components} = useQuery(['components'], () => {
    return client.fetchComponents()
  })

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (isError) {
    return (
      <span className='text-danger text-center'>{error}</span>
    )
  }

  const displayMode = props.display_mode

  let component

  if (props.has_component) {
    component = components.find(({name}) => name === props.component_name)
  }

  return (
    <>
      <div className='mb-2 bg-transparent'>
        {showsField(displayMode, 'image') && props.image_url && (
          <img
            src={props.image_url}
            className='img-fluid mb-2'
            alt=''
            {...dimensionsFromImageUrl(props.image_url)}
          />
        )}
        {showsField(displayMode, 'headline') && (
          <h4 id={toSlug(props.headline)}>{props.headline}</h4>
        )}
        {showsField(displayMode, 'intro') && props.intro && (
          <div className='lead'>{props.intro}</div>
        )}
        {showsField(displayMode, 'body') && props.body_html && (
          <Markdown className='body'>{props.body_html}</Markdown>
        )}
        {showsField(displayMode, 'component') && component && (
          <div className='bg-dark p-3' style={{ '--bs-bg-opacity': 0.125 }}>
            <h4>{component.display_name}</h4>
            <code>{props.component_config}</code>
          </div>
        )}
      </div>
    </>
  )
}

export default Item
