import React from 'react'
import {Link} from 'react-router-dom'

const MarkdownLink = ({href, children, node, ...props}) => {
  const first = href.charAt(0)
  if (first === '/') {
    return (
      <Link to={href} {...props}>{children}</Link>
    )
  } else {
    return (
      <a href={href} {...props}>{children}</a>
    )
  }
}

export default MarkdownLink
