import React, {useContext} from 'react'
import {Spinner} from 'react-bootstrap'
import {Formik, Form, Field, FieldArray} from 'formik'
import {useParams, useNavigate} from 'react-router-dom'
import {useQuery, useMutation, useQueryClient} from 'react-query'
import ApiContext from '../ApiContext'
import useDomain from '../hooks/useDomain'
import usePage from '../hooks/usePage'

import PagePicker from './PagePicker'

const BreadcrumbsEdit = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const {domainId, pageId, breadcrumbsId} = useParams()
  const {
    isLoading: domainIsLoading,
    isError: domainIsError,
    error: domainError,
    data: domain
  } = useDomain()
  const {
    isLoading: pageIsLoading,
    isError: pageIsError,
    error: pageError,
    data: page
  } = usePage()
  const {
    isLoading: breadcrumbsIsLoading,
    isError: breadcrumbsIsError,
    error: breadcrumbsError,
    data: breadcrumbs
  } = useQuery(['domains', domainId, 'breadcrumbs', breadcrumbsId], () => {
    return client.fetchBreadcrumbs(domainId, pageId)
  })

  const {client} = useContext(ApiContext)

  const updateBreadcrumbs = useMutation((breadcrumbs) => {
    return client.updateBreadcrumbs(domainId, breadcrumbs.id, breadcrumbs)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        ['domains', domainId],
      )
    }
  })

  const onSubmit = (values) => {
    updateBreadcrumbs.mutate(values, {
      onSuccess: () => {
        navigate(`/domains/${domainId}/pages/${pageId}`)
      }
    })
  }

  if (domainIsLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (domainIsError) {
    return (<span className='text-danger text-center'>{domainError}</span>)
  }

  if (pageIsLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (pageIsError) {
    return (<span className='text-danger text-center'>{pageError}</span>)
  }

  if (breadcrumbsIsLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (breadcrumbsIsError) {
    return (<span className='text-danger text-center'>{breadcrumbsError}</span>)
  }

  return (
    <>
      <h1>Edit Breadcrumbs for {page.headline}</h1>
      <Formik
        initialValues={breadcrumbs}
        onSubmit={onSubmit}
      >
        {({values: {entries}, isSubmitting}) => (
          <Form>
            <div className='mb-3'>
              <label className='form-label'>Entries</label>
              <FieldArray name='entries'>
                {({push, swap, remove}) => (
                  <>
                    <div className='mb-3'>
                    {entries.length > 0 && entries.map((entry, index) => (
                      <div className='ak-breadcrumb-entry' key={index}>
                        <div className='mb-3'>
                          <label className='form-label'>Link Text</label>
                          <Field
                            type='text'
                            name={`entries.${index}.link_text`}
                            className='form-control'
                          />
                        </div>
                        <div className='mb-3'>
                          <label className='form-label'>Target Page</label>
                          <div>
                            <PagePicker
                              pages={domain.pages}
                              name={`entries.${index}.target_page_path`}
                              linkTextField={`entries.${index}.link_text`}
                            />
                          </div>
                        </div>
                        {
                          index > 0 &&
                          (
                            <button
                              type='button'
                              className='btn btn-sm btn-outline-secondary me-2'
                              onClick={() => {
                                swap(index, index-1)
                              }}
                            >
                              Move higher
                            </button>
                          )
                        }
                        {
                          index < (entries.length - 1) &&
                          (
                            <button
                              type='button'
                              className='btn btn-sm btn-outline-secondary me-2'
                              onClick={() => {
                                swap(index, index+1)
                              }}
                            >
                              Move lower
                            </button>
                          )
                        }
                        <button
                          type='button'
                          onClick={() => {
                            remove(index)
                          }}
                          className='btn btn-sm btn-outline-danger'
                        >
                          Remove Breadcrumb Entry
                        </button>
                      </div>
                    ))}
                    </div>
                    <div>
                      <button
                        type='button'
                        className='btn btn-outline-success'
                        onClick={() => {
                          push({
                            target_page_path: '',
                            display_name: ''
                          })
                        }}
                      >
                        Add Breadcrumb Entry
                      </button>
                    </div>
                  </>
                )}
              </FieldArray>
            </div>
            <button
              type='submit'
              className='btn btn-outline-primary me-2'
            >
              Save
            </button>
            <button
              type='button'
              className='btn btn-outline-secondary'
              onClick={() => {
                navigate(`/domains/${domainId}/pages/${pageId}`)
              }}
            >
              Cancel
            </button>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default BreadcrumbsEdit
