import React, {createContext, useState} from 'react'

const MessagesContext = createContext({})

const MessagesContextProvider = ({messages: initialMessages, children}) => {
  const [messages, setMessages] = useState(initialMessages)
  const [counter, setCounter] = useState(0)

  const incrementCounter = () => {
    setCounter(counter + 1)
  }

  const addMessage = (message, type) => {
    const id = counter
    const newMessages = messages.concat({id, message, type})

    setMessages(newMessages)
    incrementCounter()
  }

  const removeMessage = (needle) => {
    const newMessages = messages.filter(({id}) => id !== needle)

    setMessages(newMessages)
  }

  return (
    <MessagesContext.Provider value={{ messages, addMessage, removeMessage }}>
      {children}
    </MessagesContext.Provider>
  )
}

export {
  MessagesContext,
  MessagesContextProvider,
}

export default MessagesContext
