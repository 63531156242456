import React, {useState} from 'react'

const SubmittableDomainSelect = ({domains, onSubmit, className}) => {

  const [selectedDomainId, setSelectedDomainId] = useState(null)



  const handleChange = ({target}) => {
    console.log("change: ", target.value)
    setSelectedDomainId(parseInt(target.value, 10))
  }

  const handleSubmit = () => {
    console.log("handle submit")
    onSubmit(selectedDomainId)
    setSelectedDomainId(null)
  }

  const list = domains.map((dom) => {
    return(
      <option
        key={dom.id}
        value={dom.id}
      >
        {dom.fqdn}
      </option>
    )
  })

  return(
    <div className='input-group'>
      <select
        className={className}
        onChange={handleChange}
      >
        <option>-- Domain wählen --</option>
       {list}
      </select>
      <button
        className='btn btn-success'
        type="button"
        onClick={handleSubmit}
        disabled={!selectedDomainId}
      >
        Link
      </button>
    </div>
  )
}

export default SubmittableDomainSelect

