import React from 'react'

const Table = ({pages, onSelect}) => (
  <table className='table table-dark table-sm align-middle'>
    <thead>
      <tr>
        <th>Headline</th>
        <th>Path</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {
        pages.map((page) => (
          <tr
            key={page.id}
            className='mb-1'
          >
            <td className='me-2'>{page.headline}</td>
            <td className='me-2'>{page.path}</td>
            <td>
              <button
                type='button'
                onClick={() => onSelect({ page })}
                className='btn btn-sm btn-outline-secondary me-2'
              >
                Select
              </button>
              <button
                type='button'
                onClick={() => onSelect({ page, copyAttributes: true })}
                className='btn btn-sm btn-outline-secondary'
              >
                Select and copy Attributes
              </button>
            </td>
          </tr>
        ))
      }
    </tbody>
  </table>

)

export default Table
