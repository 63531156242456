import React from 'react'
import PropTypes from 'prop-types'
import {useField} from 'formik'

/*
React Component choosing which Formik-Field
to display based on their values.

i.e. when user has two
mutually exclusive choices to make
(e.g. an Item may refer to either a Page
or an absolute URL but not both)

Renders only primary field (`children`)
if its form value is set.
Renders only `secondaryField`
if its form value is set (but primary is not).

Otherwise renders both fields.

Each field (primary and secondary)
should provide means to clear its value
(i.e. present a remove button).
*/
const FieldSelector = ({secondaryField, children, ...props}) => {
  const [
    /* field */,
    meta,
    /* helpers */
  ] = useField(children.props)
  const [
    /* field */,
    secondaryMeta,
    /* helpers */
  ] = useField(secondaryField.props)
  const {value} = meta
  const {value: secondaryValue} = secondaryMeta
  const AsTag = props.as || 'div'

  if (value) {
    return children
  } else if (secondaryValue) {
    return secondaryField
  } else {
    return (
      <AsTag>
        {children}{secondaryField}
      </AsTag>
    )
  }
}

FieldSelector.propTypes = {
  secondaryField: PropTypes.element.isRequired,
  children: PropTypes.element.isRequired,
  'as': PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.elementType,
  ])
}

export default FieldSelector
