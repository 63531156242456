import React, {useContext} from 'react'

import {Spinner} from 'react-bootstrap'
import {useParams, useNavigate} from 'react-router-dom'
import {useQuery} from 'react-query'

import ApiContext from '../ApiContext'
import SettingForm from './SettingForm'

const SettingEdit = () => {
  const navigate = useNavigate()
  const {client} = useContext(ApiContext)
  const {domainId, settingId} = useParams()
  const {isLoading, isError, error, data} = useQuery(['adminDomains', domainId, 'settings', settingId], () => {
    return client.fetchSetting(settingId)
  })

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (isError) {
    return (<p className='text-danger text-center'>{error.message}</p>)
  }

  const {id, item_display_modes, section_display_modes, layouts} = data

  return (
    <>
      <h1>Edit Setting</h1>
      <SettingForm
        id={id}
        section_display_modes={JSON.stringify(section_display_modes, null, "  ")}
        item_display_modes={JSON.stringify(item_display_modes, null,  "  ")}
        layouts={JSON.stringify(layouts, null, "  ")}
        onSuccess={() => {
          navigate(`/admin/domains/${data.domain_id}`)
        }}
        onCancel={() => {
          navigate(`/admin/domains/${data.domain_id}`)
        }}
      />
    </>
  )
}

export default SettingEdit
