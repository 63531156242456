import React from 'react'

const ImagePickerDetails = ({children, ...props}) => {
  const {alt, category, copyright, notes, url, width, height} = props

  return (
    <>
      <dl>
        <dt>Alt text</dt>
        <dd>
          {alt}
        </dd>
        <dt>Category</dt>
        <dd>{category}</dd>
        <dt>Copyright</dt>
        <dd>{copyright}</dd>
        <dt>Notes</dt>
        <dd>
          <p>{notes}</p>
        </dd>
        <dt className='mb-1'>
          Original Image (Dimensions: {width}x{height})
        </dt>
        <dd>
          <img
            src={url}
            className='img-fluid'
            width={width}
            height={height}
            alt={alt}
          />
        </dd>
      </dl>
      {children}
    </>
  )
}

export default ImagePickerDetails
