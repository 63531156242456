import React, {useContext} from 'react'
import {Spinner} from 'react-bootstrap'
import ApiContext from '../ApiContext'
import {useQuery, useMutation, useQueryClient} from 'react-query'
import {Link} from 'react-router-dom'
import {Table} from 'react-bootstrap'

const Users = () => {

  const queryClient = useQueryClient()
  const {client} = useContext(ApiContext)
  const {isLoading, isError, error, data} = useQuery(['users'], client.fetchUsers)

  const destroyUser = useMutation((userId) => {
    return client.destroyUser(userId)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        ['users']
      )
    }
  })

  const handleDestroy = (userId) => {
    if (window.confirm(`Really destroy User ${userId}?`)) {
      destroyUser.mutate(userId)
    }
  }

  if (isLoading) {
    return(
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (isError) {
    return(<p className='text-danger text-center'>Error: {error.message}</p>)
  }


  const renderRows = data.map(({id, email, is_admin}) => {
    return (
      <tr key={id}>
        <td>{email}</td>
        <td>{is_admin ? 'admin' : ''}</td>
        <td className='text-end'>
          <Link  to={`/admin/users/${id}`} className='btn btn-sm btn-outline-secondary me-2'>Show</Link>
          <Link  to={`/admin/users/${id}/edit`} className='btn btn-sm btn-outline-primary me-2'>Edit User</Link>
          <button
            type='button'
            className='btn btn-sm btn-outline-danger'
            onClick={() => handleDestroy(id)}
          >
            Destroy User
          </button>
        </td>
      </tr>
    )
  })

  return (
    <>
      <h1>All CMS User</h1>

      <Table className='table-responsive mb-5'>
        <thead>
          <tr>
            <th>Email</th>
            <th>Admin?</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {renderRows}
        </tbody>
      </Table>
    </>
  )
}


export default Users
