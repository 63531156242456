import React, { createContext, useState } from 'react'

const DomainContext = createContext({})

const DomainContextProvider = ({ initialDomain, children }) => {
  const [domain, setDomain] = useState(initialDomain)

  const domainSettings = domain.domain_settings
  const itemDisplayModes = domainSettings.item_display_modes
  const sectionDisplayModes = domainSettings.section_display_modes
  const layouts = domainSettings.layouts
  return (
    <DomainContext.Provider
      value={{
        domain,
        setDomain,
        domainSettings,
        itemDisplayModes,
        sectionDisplayModes,
        layouts,
      }}
    >
      {children}
    </DomainContext.Provider>
  )
}

export { DomainContext, DomainContextProvider }

export default DomainContext
