import {useContext} from 'react'

import ApiContext from '../ApiContext'
import {useParams} from 'react-router-dom'
import {useQuery} from 'react-query'

const useDomain = () => {
  const {client} = useContext(ApiContext)
  const {domainId} = useParams()
  return useQuery(['domains', domainId], () => client.fetchDomain(domainId))
}

export default useDomain
