import React from 'react'
import PropTypes from 'prop-types'

import { useMediaQuery } from '@react-hook/media-query'
import { useVirtualizer } from '@tanstack/react-virtual'

const ImagePickerCards = ({ children, data }) => {
  const mdUp = useMediaQuery('(min-width: 768px)')
  const lgUp = useMediaQuery('(min-width: 992px)')
  const xlUp = useMediaQuery('(min-width: 1200px)')
  const columnCount = xlUp ? 6 : (lgUp ? 5 : (mdUp ? 3 : 2))

  const parentRef = React.useRef(null)

  const generateImageGridData = (colCount, data) => {
    const rowCount = Math.ceil(data.length / colCount)

    return new Array(rowCount).fill(0).map((_, rowIndex) => {
      const startIndex = rowIndex * colCount
      const endIndex = (rowIndex * colCount) + colCount

      return data.slice(startIndex, endIndex)
    })
  }

  const imageGridData = generateImageGridData(columnCount, data)

  const virtualizer = useVirtualizer({
    count: imageGridData.length,
    estimateSize: () => 75,
    getScrollElement: () => parentRef.current,
    overscan: 5,
  })

  const rows = virtualizer.getVirtualItems().map((row) => {
    return (
      <div
        key={row.key}
        data-index={row.index}
        ref={virtualizer.measureElement}
        style={{
          transform: `translateY(${row.start}px)`,
        }}
        className='image-picker-grid-row'
      >
        {new Array(columnCount).fill(0).map((_, columnIndex) => {
          const img = imageGridData[row.index][columnIndex]

          if (!img) {
            return (
              <React.Fragment
                key={`image-picker-grid-${row.index}-${columnIndex}`}
              />
            )
          }

          return children(img)
        })}
      </div>
    )
  })

  return (
    <div
      ref={parentRef}
      className='image-picker-grid mb-4'
    >
      <div
        style={{
          height: virtualizer.getTotalSize(),
          position: 'relative',
        }}
      >
        {rows}
      </div>
    </div>
  )
}

ImagePickerCards.propTypes = {
  data: PropTypes.array.isRequired,
  children: PropTypes.func.isRequired,
}

export default ImagePickerCards
