import React from 'react'
import {Spinner} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'

import MenuForm from './MenuForm'

import useMenu from '../hooks/useMenu'

const MenuEdit = () => {
  const navigate = useNavigate()
  const {domainId, menuId} = useParams()
  const {isLoading, isError, error, data} = useMenu()

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (isError) {
    return (<p className='text-danger text-center'>{error.message}</p>)
  }

  return (
    <>
      <h2>Edit Menu</h2>
      <MenuForm
        {...data}
        onSuccess={() => {
          console.log('been there')
          navigate(`/domains/${domainId}/menus/${menuId}`)
        }}
        onCancel={() => {
          navigate(`/domains/${domainId}/menus/${menuId}`)
        }}
      />
    </>
  )
}

export default MenuEdit
