import React, {createContext, useState} from 'react'

import ImageLibraryClient from 'image_library_client'

const ImageLibraryApiContext = createContext({})

const ImageLibraryApiContextProvider = ({apiUrl, apiToken, scope, children}) => {

  const [imageLibraryClient, setImageLibraryClient] = useState(new ImageLibraryClient(apiUrl, apiToken, scope))

  const setScope = (newScope) => {
    setImageLibraryClient(new ImageLibraryClient(apiUrl, apiToken, newScope))
  }

  return (
    <ImageLibraryApiContext.Provider
      value={{imageLibraryClient, setScope}}
    >
      {children}
    </ImageLibraryApiContext.Provider>
  )
}

export {
  ImageLibraryApiContext,
  ImageLibraryApiContextProvider
}

export default ImageLibraryApiContext
