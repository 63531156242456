import React, {useContext} from 'react'
import {ImageUrlSigningContext} from 'ak-react-utils'

const imgConfig = {
  resizingType: 'fit',
  width: 100,
  height: 100,
  gravity: 'sm',
  enlarge: 1,
  extension: 'webp',
}

const ImagePickerCard = ({onClick, preview, children, ...img}) => {
  const {urlSigner} = useContext(ImageUrlSigningContext)

  return (
    <div className='col mb-4' key={img.id}>
      <div className='card border-0 bg-dark shadow'>
        <button
          type='button'
          className='border-0 bg-transparent p-0'
          onClick={() => onClick(img)}
        >
          <img
            className='card-img-top h-auto'
            src={urlSigner.create(img.url, imgConfig)}
            width={img.width}
            height={img.height}
            alt=''
          />
        </button>
        {preview && (
          <div className='card-body'>
            <div className='card-title'>
              {img.alt}
            </div>
            <div className='d-grid gap-2'>
              {children}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ImagePickerCard
