import {useContext} from 'react'

import ApiContext from '../ApiContext'
import {useParams} from 'react-router-dom'
import {useQuery} from 'react-query'

const usePage = () => {
  const {client} = useContext(ApiContext)
  const {domainId, pageId} = useParams()

  return useQuery(['domains', domainId, 'pages', pageId], () => client.fetchPage(domainId, pageId))
}

export default usePage
