const prepareApiRequest = ({ apiUrl, apiToken, endpoint }) => {
  return {
    url: `${apiUrl}${endpoint}`,
    headers: {
      'Authorization': `Bearer ${apiToken}`
    },
    mode: 'cors'
  }
}

const withResponseHandling = async (res) => {
  let json
  try {
    json = await res.json()
  } catch(e) {
    throw new Error(`Content is kein JSON: ${e.message}`)
  }

  if (res.ok) {
    return json
  } else {
    throw new Error(`Api Error: ${JSON.stringify(json)}`)
  }
}

const withStatusHandling = async (res) => {
  if (res.ok) {
    return res
  } else {
    throw new Error(`Api Error: ${await res.text()}`)
  }
}

const createApiClient = ({apiUrl, apiToken}) => {
  return {
    fetchMe: async () => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/mgmt/api/v1/me`
      })

      const res = await fetch(url, {
        headers: headers
      })
      return withResponseHandling(res)
    },
    fetchUsers: async () => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/adm/api/v1/users`
      })

      const res = await fetch(url, {
        headers: headers
      })
      return withResponseHandling(res)
    },
    fetchUser: async (userId) => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/adm/api/v1/users/${userId}`
      })

      const res = await fetch(url, {
        headers: headers
      })
      return withResponseHandling(res)
    },
    updateUser: async (id, user) => {
      const { url, headers } = prepareApiRequest({
        apiUrl,
        apiToken,
        endpoint: `/adm/api/v1/users/${id}`
      })
      const res = await fetch(url, {
        headers: headers,
        method: 'PATCH',
        body: JSON.stringify(user)

      })
      return withResponseHandling(res)
    },
    fetchSettings: async () => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: '/adm/api/v1/settings'
      })

      const res = await fetch(url, {
        headers: headers
      })
      return withResponseHandling(res)
    },
    fetchSetting: async (id) => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/adm/api/v1/settings/${id}`
      })

      const res = await fetch(url, {
        headers: headers
      })
      return withResponseHandling(res)
    },
    createSetting: async (domainId, setting) => {
      const { url, headers } = prepareApiRequest({
        apiUrl,
        apiToken,
        endpoint: `/adm/api/v1/domains/${domainId}/settings`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'POST',
        body: JSON.stringify(setting)
      })

      return withResponseHandling(res)
    },
    updateSetting: async (id, setting) => {
      const { url, headers } = prepareApiRequest({
        apiUrl,
        apiToken,
        endpoint: `/adm/api/v1/settings/${id}`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'PATCH',
        body: JSON.stringify(setting)
      })

      return withResponseHandling(res)
    },
    destroyUser: async (id) => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/adm/api/v1/users/${id}`
      })
      const res = await fetch(url, {
        headers: headers,
        method: 'DELETE'
      })

      return withStatusHandling(res)
    },
    linkUserDomain: async (userId, domainId) => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/adm/api/v1/users/${userId}/domains`
      })
      const res = await fetch(url, {
        headers: headers,
        method: 'POST',
        body: JSON.stringify({domain_id: parseInt(domainId, 10)})
      })

      return withResponseHandling(res)
    },
    unlinkUserDomain: async (userId, domainId) => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/adm/api/v1/users/${userId}/domains/${domainId}`
      })
      const res = await fetch(url, {
        headers: headers,
        method: 'DELETE'
      })

      return withStatusHandling(res)
    },
    destroySetting: async (id) => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/mgmt/api/v1/settings/${id}`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'DELETE'
      })
      return withStatusHandling(res)
    },
    fetchComponents: async () => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: '/mgmt/api/v1/components'
      })

      const res = await fetch(url, {
        headers: headers
      })
      return withResponseHandling(res)
    },
    fetchComponent: async (id) => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/mgmt/api/v1/components/${id}`
      })

      const res = await fetch(url, {
        headers: headers
      })
      return withResponseHandling(res)
    },
    createComponent: async (component) => {
      const { url, headers } = prepareApiRequest({
        apiUrl,
        apiToken,
        endpoint: `/adm/api/v1/components`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'POST',
        body: JSON.stringify(component)
      })

      return withResponseHandling(res)
    },
    updateComponent: async (id, component) => {
      const { url, headers } = prepareApiRequest({
        apiUrl,
        apiToken,
        endpoint: `/adm/api/v1/components/${id}`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'PATCH',
        body: JSON.stringify(component)
      })

      return withResponseHandling(res)
    },
    destroyComponent: async (id) => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/adm/api/v1/components/${id}`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'DELETE'
      })

      return withStatusHandling(res)
    },
    fetchDomains: async () => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: '/mgmt/api/v1/domains'
      })

      const res = await fetch(url, {
        headers: headers
      })

      return withResponseHandling(res)
    },
    fetchDomain: async (id) => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/mgmt/api/v1/domains/${id}`
      })

      const res = await fetch(url, {
        headers: headers
      })

      return withResponseHandling(res)
    },

    fetchClientTokens: async () => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: '/adm/api/v1/client_tokens'
      })

      const res = await fetch(url, {
        headers: headers
      })

      return withResponseHandling(res)
    },

    fetchClientToken: async (id) => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/adm/api/v1/client_tokens/${id}`
      })

      const res = await fetch(url, {
        headers: headers
      })

      return withResponseHandling(res)
    },
    updateClientToken: async (id, token) => {
      console.log("token: ", token)
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/adm/api/v1/client_tokens/${id}`
      })
      const res = await fetch(url, {
        headers: headers,
        method: 'PATCH',
        body: JSON.stringify(token)
      })
      return withResponseHandling(res)
    },
    destroyClientToken: async (id) => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/adm/api/v1/client_tokens/${id}`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'DELETE'
      })

      return withStatusHandling(res)
    },
    createClientToken: async (domainId, token) => {
      const { url, headers } = prepareApiRequest({
        apiUrl,
        apiToken,
        endpoint: `/adm/api/v1/domains/${domainId}/client_tokens`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'POST',
        body: JSON.stringify(token)
      })

      return withResponseHandling(res)
    },
    fetchAdminDomains: async () => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: '/adm/api/v1/domains'
      })

      const res = await fetch(url, {
        headers: headers
      })

      return withResponseHandling(res)
    },
    fetchAdminDomain: async (id) => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/adm/api/v1/domains/${id}`
      })

      const res = await fetch(url, {
        headers: headers
      })

      return withResponseHandling(res)
    },

    updateDomain: async (id, domain) => {
      const { url, headers } = prepareApiRequest({
        apiUrl,
        apiToken,
        endpoint: `/mgmt/api/v1/domains/${id}`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'PATCH',
        body: JSON.stringify(domain)
      })

      return withResponseHandling(res)
    },
    createDomain: async (domain) => {
      const { url, headers } = prepareApiRequest({
        apiUrl,
        apiToken,
        endpoint: `/adm/api/v1/domains`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'POST',
        body: JSON.stringify(domain)
      })

      return withResponseHandling(res)
    },
    destroyDomain: async (id) => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/adm/api/v1/domains/${id}`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'DELETE'
      })

      return withStatusHandling(res)
    },
    triggerCi: async (domainId) => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/mgmt/api/v1/domains/${domainId}/trigger_ci`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'POST'
      })

      return withStatusHandling(res)
    },
    fetchMenus: async (domainId) => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/mgmt/api/v1/domains/${domainId}/menus`
      })

      const res = await fetch(url, {
        headers: headers
      })

      return withResponseHandling(res)
    },
    fetchMenu: async (domainId, id) => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/mgmt/api/v1/domains/${domainId}/menus/${id}`
      })

      const res = await fetch(url, {
        headers: headers
      })

      return withResponseHandling(res)
    },
    createMenu: async (domainId, menu) => {
      const { url, headers } = prepareApiRequest({
        apiUrl,
        apiToken,
        endpoint: `/mgmt/api/v1/domains/${domainId}/menus`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'POST',
        body: JSON.stringify(menu)
      })

      return withResponseHandling(res)
    },
    updateMenu: async (domainId, id, menu) => {
      const { url, headers } = prepareApiRequest({
        apiUrl,
        apiToken,
        endpoint: `/mgmt/api/v1/domains/${domainId}/menus/${id}`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'PATCH',
        body: JSON.stringify(menu)
      })

      return withResponseHandling(res)
    },
    destroyMenu: async (domainId, id) => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/mgmt/api/v1/domains/${domainId}/menus/${id}`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'DELETE'
      })

      return withStatusHandling(res)
    },
    fetchDisplayModeUsage: async (domainId) => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/mgmt/api/v1/domains/${domainId}/display_modes`
      })

      const res = await fetch(url, {
        headers: headers
      })

      return withResponseHandling(res)
    },
    fetchBreadcrumbs: async (domainId, pageId) => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/mgmt/api/v1/domains/${domainId}/pages/${pageId}/breadcrumbs`
      })

      const res = await fetch(url, {
        headers: headers
      })

      return withResponseHandling(res)
    },
    createBreadcrumbs: async (domainId, pageId, breadcrumbs) => {
      const { url, headers } = prepareApiRequest({
        apiUrl,
        apiToken,
        endpoint: `/mgmt/api/v1/domains/${domainId}/pages/${pageId}/breadcrumbs`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'POST',
        body: JSON.stringify(breadcrumbs)
      })

      return withResponseHandling(res)
    },
    updateBreadcrumbs: async (domainId, id, breadcrumbs) => {
      const { url, headers } = prepareApiRequest({
        apiUrl,
        apiToken,
        endpoint: `/mgmt/api/v1/domains/${domainId}/breadcrumbs/${id}`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'PATCH',
        body: JSON.stringify(breadcrumbs)
      })

      return withResponseHandling(res)
    },
    destroyBreadcrumbs: async (domainId, id) => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/mgmt/api/v1/domains/${domainId}/breadcrumbs/${id}`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'DELETE'
      })

      return withStatusHandling(res)
    },
    fetchPage: async (domainId, id) => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/mgmt/api/v1/domains/${domainId}/pages/${id}`
      })

      const res = await fetch(url, {
        headers: headers
      })

      return withResponseHandling(res)
    },
    createPage: async (domainId, page) => {
      const { url, headers } = prepareApiRequest({
        apiUrl,
        apiToken,
        endpoint: `/mgmt/api/v1/domains/${domainId}/pages`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'POST',
        body: JSON.stringify(page)
      })

      return withResponseHandling(res)
    },
    updatePage: async (domainId, id, page) => {
      const { url, headers } = prepareApiRequest({
        apiUrl,
        apiToken,
        endpoint: `/mgmt/api/v1/domains/${domainId}/pages/${id}`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'PATCH',
        body: JSON.stringify(page)
      })

      return withResponseHandling(res)
    },
    destroyPage: async (domainId, id) => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/mgmt/api/v1/domains/${domainId}/pages/${id}`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'DELETE'
      })

      return withStatusHandling(res)
    },
    createSection: async (domainId, section) => {
      const { url, headers } = prepareApiRequest({
        apiUrl,
        apiToken,
        endpoint: `/mgmt/api/v1/domains/${domainId}/sections`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'POST',
        body: JSON.stringify(section)
      })

      return withResponseHandling(res)
    },
    updateSection: async (domainId, id, section) => {
      const { url, headers } = prepareApiRequest({
        apiUrl,
        apiToken,
        endpoint: `/mgmt/api/v1/domains/${domainId}/sections/${id}`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'PATCH',
        body: JSON.stringify(section)
      })

      return withStatusHandling(res)
    },
    destroySection: async (domainId, id) => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/mgmt/api/v1/domains/${domainId}/sections/${id}`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'DELETE'
      })

      return withStatusHandling(res)
    },
    moveSectionHigher: async (domainId, id) => {
      const { url, headers } = prepareApiRequest({
        apiUrl,
        apiToken,
        endpoint: `/mgmt/api/v1/domains/${domainId}/sections/${id}/up`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'POST'
      })

      return withStatusHandling(res)
    },
    moveSectionLower: async (domainId, id) => {
      const { url, headers } = prepareApiRequest({
        apiUrl,
        apiToken,
        endpoint: `/mgmt/api/v1/domains/${domainId}/sections/${id}/down`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'POST'
      })

      return withStatusHandling(res)
    },
    createItem: async (domainId, item) => {
      const { url, headers } = prepareApiRequest({
        apiUrl,
        apiToken,
        endpoint: `/mgmt/api/v1/domains/${domainId}/items`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'POST',
        body: JSON.stringify(item)
      })

      return withResponseHandling(res)
    },
    updateItem: async (domainId, id, item) => {
      const { url, headers } = prepareApiRequest({
        apiUrl,
        apiToken,
        endpoint: `/mgmt/api/v1/domains/${domainId}/items/${id}`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'PUT',
        body: JSON.stringify(item)
      })

      return withResponseHandling(res)
    },
    destroyItem: async (domainId, id) => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/mgmt/api/v1/domains/${domainId}/items/${id}`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'DELETE'
      })

      return withResponseHandling(res)
    },
    moveItemHigher: async (domainId, id) => {
      const { url, headers } = prepareApiRequest({
        apiUrl,
        apiToken,
        endpoint: `/mgmt/api/v1/domains/${domainId}/items/${id}/up`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'POST'
      })

      return withStatusHandling(res)
    },
    moveItemLower: async (domainId, id) => {
      const { url, headers } = prepareApiRequest({
        apiUrl,
        apiToken,
        endpoint: `/mgmt/api/v1/domains/${domainId}/items/${id}/down`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'POST'
      })

      return withStatusHandling(res)
    },
  }
}

export {
  createApiClient
}
