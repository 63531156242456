import React, {useEffect, useState, useContext} from 'react'

import {useParams, Link} from 'react-router-dom'
import {useQueryClient, useMutation} from 'react-query'
import {Spinner, Breadcrumb} from 'react-bootstrap'

import {dimensionsFromImageUrl} from 'ak-react-utils'

import {ApiContext} from '../ApiContext'

import usePage from '../hooks/usePage'
import useDomain from '../hooks/useDomain'

import Section from './Section'

import EditablePage from './EditablePage'
import PageMetadataForm from './PageMetadataForm'
import { DomainContextProvider } from '../DomainContext'
import { makePageUtils } from '../utils'

const Page = ({ editableMode }) => {
  const queryClient = useQueryClient()
  const [mode, setMode] = useState('show')
  const {domainId, pageId} = useParams()
  const {client} = useContext(ApiContext)
  const domainQuery = useDomain()
  const pageQuery = usePage()

  const updatePage = useMutation(page => {
    return client.updatePage(domainId, pageId, page)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        ['domains', domainId]
      )
    }
  })

  const createBreadcrumbs = useMutation(() => {
    return client.createBreadcrumbs(domainId, pageId, {entries: []})
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        ['domains', domainId]
      )
    }
  })

  useEffect(() => {
    setMode(editableMode ? 'editable' : 'show')
  }, [setMode, editableMode])

  if (pageQuery.isLoading || domainQuery.isLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (pageQuery.isError) {
    return (<p className='text-danger text-center'>{pageQuery.error.message}</p>)
  }

  if (domainQuery.isError) {
    return (<p className='text-danger text-center'>{domainQuery.error.message}</p>)
  }

  const {
    headline,
    subline,
    lead_text,
    sections,
    image_url,
    start_page_domain_id,
    breadcrumbs,
  } = pageQuery.data

  const domain = domainQuery.data

  const {
    pages,
    layout,
  } = domain

  const hasBreadcrumbs = !!breadcrumbs.id

  const findPageByPath = (pathToFind) => {
    return pages.find(({path}) => path === pathToFind)
  }

  const isStartPage = parseInt(domainId, 10) === start_page_domain_id

  const setAsStartPage = () => {
    const page = Object.assign({}, pageQuery.data, {start_page_domain_id: parseInt(domainId, 10)})
    updatePage.mutate(page)
  }

  const {showsField} = makePageUtils(domain)

  const renderableSections = (
    <>
      {sections.filter((section) => { return section.published }).map((section, index) => (
        <div className='mb-5' key={section.id}>
          <Section {...section}>
          </Section>
        </div>
      ))}
    </>
  )

  switch (mode) {
    case 'editable':
      return (
        <>
          <DomainContextProvider initialDomain={domain}>
            <EditablePage
              {...pageQuery.data}
              onFinish={() => setMode('show')}
            />
          </DomainContextProvider>
        </>
      )
    case 'metadata':
      return (
        <>
          <PageMetadataForm
            {...pageQuery.data}
            onFinish={() => setMode('show')}
          />
        </>
      )
    case 'show':
    default:
      let renderableBreadcrumbs = (<></>)

      if (hasBreadcrumbs) {
        renderableBreadcrumbs = (
          <div>
            <Breadcrumb>
              {breadcrumbs.entries.map(({target_page_path, link_text}) => {
                const targetPage = findPageByPath(target_page_path)

                if (targetPage) {

                  return (
                    <Breadcrumb.Item
                      key={target_page_path}
                      linkAs={Link}
                      linkProps={{
                        to: `/domains/${domainId}/pages/${targetPage.id}`
                      }}
                    >
                      {link_text}
                    </Breadcrumb.Item>
                  )
                } else {
                  return (
                    <Breadcrumb.Item key={target_page_path} >
                      {link_text} <span className='text-danger'>(broken: {target_page_path})</span>
                    </Breadcrumb.Item>
                  )
                }
              })}
                  </Breadcrumb>
          </div>
        )
      }


      return (
        <DomainContextProvider initialDomain={domain}>
          <section className='mb-5 d-flex align-items-center'>
            <Link
              className='btn btn-outline-primary me-2'
              to={`/domains/${domainId}/pages/${pageId}/change`}
            >
              Change Page
            </Link>
            {hasBreadcrumbs
            ?
             (
               <Link
                 to={`/domains/${domainId}/pages/${pageId}/breadcrumbs/${breadcrumbs.id}/edit`}
                 className='btn btn-outline-dark me-2'
               >
                 Edit Breadcrumbs
               </Link>
             )
            :
             (
               <button
                 className='btn btn-outline-success me-2'
                 type='button'
                 onClick={() => {
                   createBreadcrumbs.mutate()
                 }}
               >
                 Create Breadcrumbs
               </button>
             )
            }
            <button
              className='btn btn-outline-dark me-2'
              type='button'
              onClick={() => setMode('metadata')}
            >
              Edit Page Metadata
            </button>
            {isStartPage
            ?
             (
               <span>This is the Start Page</span>
             )
            :
             (
               <button
                 className='btn btn-outline-info'
                 type='button'
                 onClick={setAsStartPage}
               >
                 Set as Start Page
               </button>

             )
            }
          </section>
          {renderableBreadcrumbs}
          {showsField(layout, 'image') && image_url && (
            <img
              src={image_url}
              {...dimensionsFromImageUrl(image_url)}
              className='img-fluid mb-3'
              alt=''
            />
          )}
          <strong className='mb-4'>{headline}{showsField(layout, 'subline') && subline && (
              <>
                <br/>
                <small className='text-muted'>{subline}</small>
              </>
          )}
          </strong>
          {showsField(layout, 'lead_text') && lead_text && (<p className='lead'>
            {lead_text}
          </p>)}
          {renderableSections}
        </DomainContextProvider>
      )
  }
}
export default Page
