import React, {useContext} from 'react'

import {Spinner} from 'react-bootstrap'
import {Formik, Form, Field} from 'formik'
import {useNavigate} from 'react-router-dom'
import {useQueryClient, useMutation} from 'react-query'
import {ApiContext} from '../ApiContext'
import useUser from '../hooks/useUser'

const UserEdit = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const {isLoading, isError, error, data} = useUser()
  const {client} = useContext(ApiContext)

  const updateUser = useMutation(user => {
    return client.updateUser(data.id, user)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(['users'])
    }
  })

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (isError) {
    return (
      <p className='text-danger text-center'>{error.message}</p>
    )
  }

  return (
    <>
      <h2>Edit {data.email}</h2>
      <div className='bg-warning bg-opacity-10 p-3'>
        <Formik
          initialValues={data}
          onSubmit={ (values) => {
            updateUser.mutate(values,{
              onSuccess: () => navigate('../')
            })
          }}
        >
        {({isSubmitting}) => (
          <Form>
            <div className='form-group mb-5'>
              <label>
              <Field type="checkbox" name="is_admin" className='me-3' />
                  Admin
              </label>
            </div>
            <button
              type='submit'
              disabled={isSubmitting}
              className='btn btn-sm btn-outline-primary me-2'
            >
              Save
            </button>
            <button
              type='button'
              className='btn btn-sm btn-outline-secondary'
              onClick={() => navigate("../")}
            >
              Cancel
            </button>
          </Form>
        )}
        </Formik>
      </div>
    </>
  )
}

export default UserEdit
