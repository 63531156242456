import {useContext} from 'react'
import ApiContext from '../ApiContext'
import {useQuery} from 'react-query'


const useMe = () => {
  const {client} = useContext(ApiContext)
  return useQuery(['me'], () => client.fetchMe())
}

export default useMe
