import React from 'react'
import {useNavigate} from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import useAdminDomain from '../../hooks/useAdminDomain'
import DomainForm from './DomainForm'

const DomainEdit = () => {
  const navigate = useNavigate()
  const {isLoading, isError, error, data} = useAdminDomain()

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (isError) {
    return (<span className='text-danger text-center'>{error}</span>)
  }

  return (
    <>
      <h1>Edit Domain</h1>
      <div className='bg-warning bg-opacity-10 p-3'>
        <DomainForm
          {...data}
          onSuccess={() => navigate(`/admin/domains/${data.id}`)}
          onCancel={() => navigate(`/admin/domains/${data.id}`)}
        />
      </div>
    </>
  )
}

export default DomainEdit
