/*
   This module most likely will be extracted into ak-react-utils
   as soon as other consumers need it...
 */
import React, {createContext, useState} from 'react'

const AuthContext = createContext({})

const AuthContextProvider = ({authClient, children}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(authClient.hasAccessToken())
  const [accessToken, setAccessToken] = useState(authClient.accessToken())

  const beginLogin = ({ originalUrl }) => {
    authClient.authorize({ originalUrl })
  }

  const completeLogin = async () => {
    const originalUrl = await authClient.exchangeAuthCodeForAccessToken()

    setAccessToken(authClient.accessToken())
    setIsLoggedIn(true)

    return originalUrl
  }

  const hasPendingLogin = () => {
    return authClient.hasPendingAccessToken()
  }

  return (
    <AuthContext.Provider
      value={{ beginLogin, completeLogin, hasPendingLogin, isLoggedIn, accessToken }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export {
  AuthContext,
  AuthContextProvider,
}

export default AuthContext
