import React, {createContext, useState} from 'react'

const ApiContext = createContext({})

const ApiContextProvider = ({apiClient, children}) => {
  const [client, setClient] = useState(apiClient)
  return (
    <ApiContext.Provider value={{ client, setClient }}>
      {children}
    </ApiContext.Provider>
  )
}

export {
  ApiContext,
  ApiContextProvider,
}

export default ApiContext
