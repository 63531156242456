import React, {useContext} from 'react'

import {Formik, Form, Field} from 'formik'
import {useMutation, useQueryClient} from 'react-query'

import {fileToBase64} from 'image_library_client'

import ImageLibraryApiContext from '../../ImageLibraryApiContext'
import ExtendableSelectField from './ExtendableSelectField'

const ImagePickerForm = ({onSuccess, onCancel, categoryOptions, id: imageId, ...props}) => {
  const queryClient = useQueryClient()
  const {imageLibraryClient} = useContext(ImageLibraryApiContext)

  const createOrUpdateImage = useMutation(image => {
    if (imageId) {
      return imageLibraryClient.updateImage(imageId, image)
    } else {
      return imageLibraryClient.createImage(image)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(['images'])
    }
  })

  const onSubmit = async (values) => {
    if (values['file']) {
      values['data'] = await fileToBase64(values['file'])
    }

    await createOrUpdateImage.mutateAsync(values)

    onSuccess()
  }

  const ImagePreview = ({file, fallbackUrl}) => {
    let url

    if (file) {
      url = URL.createObjectURL(file)
    }
    else {
      url = fallbackUrl
    }

    if (url) {
      return (<img src={url} width='150' alt='' />)
    } else {
      return (<></>)
    }
  }

  const {alt, category, copyright, notes, url} = props

  return (
    <Formik
      initialValues={{
        alt,
        category,
        copyright,
        notes,
        // An empty file reference to initialize the form field
        file: ''
      }}
      onSubmit={onSubmit}
    >{
      ({isSubmitting, setFieldValue, values}) => (
        <Form>
          <div className='form-group mb-3'>
            <label>Descriptive Name</label>
            <Field
              type='text'
              name='alt'
              className='form-control'
            />
          </div>
          <div className='form-group mb-3'>
            <label>Category</label>
            <ExtendableSelectField name='category'>
              <option value='-- Choose a Category --' />
              {categoryOptions}
            </ExtendableSelectField>
          </div>
          <div className='form-group mb-3'>
            <label>Copyright</label>
            <Field
              type='text'
              name='copyright'
              className='form-control'
            />
          </div>
          <div className='form-group mb-3'>
            <label>Notes</label>
            <Field
              type='text'
              as='textarea'
              name='notes'
              className='form-control'
            />
          </div>
          <div className='form-group mb-3'>
            <label className='d-block'>File</label>
            {!imageId && (
              <input
                type='file'
                name='file'
                className='form-control mb-2'
                onChange={(event) => {
                  setFieldValue('file', event.currentTarget.files[0])
                }}
              />
            )}
            <ImagePreview
              file={values.file}
              fallbackUrl={url}
            />
          </div>
          <div className='d-flex align-items-center'>
            <button
              type='submit'
              disabled={isSubmitting}
              className='btn btn-outline-success me-2'
            >
              {imageId ? 'Save' : 'Create'}
            </button>
            <button
              type='button'
              disabled={isSubmitting}
              onClick={onCancel}
              className='btn btn-outline-light me-2'
            >
              Cancel
            </button>
            {isSubmitting && (
              <div className='spinner-border spinner-border-sm text-light'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            )}
          </div>
        </Form>
      )
    }</Formik>
  )
}

export default ImagePickerForm
