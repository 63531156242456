import React, {useContext} from 'react'

import {ImageUrlSigningContext} from 'ak-react-utils'

const imgConfig = {
  resizingType: 'fit',
  width: 64,
  height: 64,
  gravity: 'sm',
  enlarge: 1,
  extension: 'webp',
}

const ImagePickerRow = ({children, ...props}) => {
  const {urlSigner} = useContext(ImageUrlSigningContext)

  const {alt, category, copyright, url} = props

  return (
    <tr>
      <td>
        <img
          src={urlSigner.create(url, imgConfig)}
          className='img-fluid'
          width='64'
          alt={alt}
        />
      </td>
      <td>{category}</td>
      <td>{alt}</td>
      <td>{copyright}</td>
      <td>
        <div
          className='grid gap-2'
          style={{ '--bs-columns': 3}}
        >
          {children}
        </div>
      </td>
    </tr>
  )
}

export default ImagePickerRow
