import {useContext} from 'react'
import ApiContext from '../ApiContext'
import {useParams} from 'react-router-dom'
import {useQuery} from 'react-query'


const useComponent = () => {
  const {client} = useContext(ApiContext)
  const {componentId} = useParams()
  return useQuery(['components', componentId], () => client.fetchComponent(componentId))
}

export default useComponent
