import React, {useContext} from 'react'
import {Spinner} from 'react-bootstrap'
import ApiContext from '../ApiContext'
import {useQuery, useMutation, useQueryClient} from 'react-query'
import {Link} from 'react-router-dom'

const Components = () => {
  const queryClient = useQueryClient()
  const {client} = useContext(ApiContext)

  const {isLoading, isError, error, data} = useQuery(['components'], client.fetchComponents)

  const destroyComponent = useMutation((componentId) => {
    return client.destroyComponent(componentId)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        ['components']
      )
    }
  })

  const handleDestroy = (componentId) => {
    if (window.confirm(`Really destroy Component ${componentId}?`)) {
      destroyComponent.mutate(componentId)
    }
  }

  if (isLoading) {
    return(
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (isError) {
    return(<p className='text-danger text-center'>Error: {error.message}</p>)
  }

  return (
    <>
      <h1>All CMS Components</h1>
      <table className='table table-responsive mb-5'>
        <thead>
          <tr>
            <th>
              Name
            </th>
            <th>
              Display Name
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map(({id, name, display_name}) => (
            <tr key={id}>
              <td>{name}</td>
              <td>{display_name}</td>
              <td className='text-end'>
                <Link
                  to={`/admin/components/${id}`}
                  className='btn-outline-secondary btn btn-sm me-2'
                >
                  Show
                </Link>
                <Link
                  to={`/admin/components/${id}/edit`}
                  className='btn btn-sm btn-outline-primary me-2'
                >
                  Edit Component
                </Link>
                <button
                  type='button'
                  className='btn btn-sm btn-outline-danger'
                  onClick={() => handleDestroy(id)}
                >
                  Destroy Component
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Link
        className='btn btn-outline-primary'
        to='/admin/components/new'
      >
        New Component
      </Link>
    </>
  )
}


export default Components
