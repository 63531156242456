import React, {useContext} from 'react'

import {Link, Navigate} from 'react-router-dom'

import AuthContext from '../AuthContext'

const Home = () => {
  const {isLoggedIn} = useContext(AuthContext)

  if (isLoggedIn) {
    return (<Navigate to="/domains" />)
  }

  return (
    <>
      <h1>Welcome to AkCmsAdmin!</h1>
      {!isLoggedIn && (
        <Link to="/login">Login</Link>
      )}
    </>
  )
}

export default Home
