import React, {useContext} from 'react'
import {Spinner, Form as BSForm, Alert} from 'react-bootstrap'
import {useParams} from 'react-router-dom'
import {Formik, Form, Field} from 'formik'
import {useQuery, useQueryClient, useMutation} from 'react-query'

import {layoutConfigFor, makePageUtils} from '../utils'

import {ApiContext} from '../ApiContext'
import {DomainContext} from '../DomainContext'
import {MessagesContext} from '../MessagesContext'
import ImagePicker from './ImagePicker'
import ImageUrlField from './ImageUrlField'
import LayoutInfo from './LayoutInfo'
import AlertFormFields from './AlertFormFields'

const PageForm = ({onSuccess, onCancel}) => {
  const queryClient = useQueryClient()
  const {addMessage} = useContext(MessagesContext)

  const {domainId, pageId} = useParams()
  const {client} = useContext(ApiContext)
  const {
    domain,
    domainSettings,
    layouts,
  } = useContext(DomainContext)
  const {configuresFields, showsField,nameForMode,descriptionForMode} = makePageUtils(domain)

  const {isLoading, isError, error, data} = useQuery(
    ['domains', domainId, 'pages', pageId],
    client.fetchPage(domainId, pageId)
  )

  const updatePage = useMutation(changedPage => {
    return client.updatePage(domainId, pageId, changedPage)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        ['domains', domainId, 'pages', pageId]
      )
    }
  })

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (isError) {
    return (<p className='text-danger text-center'>{error.message}</p>)
  }

  const layoutOptions = layouts.map((m,i) => {
    return(
      <option key={'layout-' + i} value={m['name']}>{m['name']}</option>
    )
  })

  const {
    headline,
    subline,
    lead_text,
    image_id,
    image_url,
    breadcrumbs,
    layout,
    published,
    path,
  } = data

  const {page_image_config} = layoutConfigFor(layout, domainSettings)

  return (
    <div className='shadow p-3'>
      <h1>Edit Page</h1>
      <Formik
        initialValues={{
          path,
          headline,
          subline,
          lead_text,
          image_id,
          image_url,
          breadcrumbs,
          layout,
          published,
        }}
        onSubmit={(values, formikBag) => {
          updatePage.mutate(values, {
            onSuccess: () => {
              formikBag.setSubmitting(false)
              onSuccess()
            },
            onError: (err) => {
              addMessage(`Cannot save Page: ${err.message}`, 'error')
              formikBag.setSubmitting(false)
            }
          })
        }}
      >
        {({isSubmitting, values: { image_url, layout }}) => (
          <Form className='vstack gap-3'>
            <div className='form-group'>
              <label className='form-label'>
                Layout
                <LayoutInfo.Trigger domainSettings={domainSettings} />
              </label>
              <Field
                as='select'
                name='layout'
                className='form-select'
              >
                <option value=''>--Select Layout--</option>
                { layoutOptions }
              </Field>
              <div className='form-text'>
                Decide how this Page is shown on the website.
                Most of the time tells you how images are cropped/resized
                and its sections will be laid out.
              </div>
            </div>
            {!configuresFields(layout) && (<AlertFormFields />)}
            <Alert variant='info' className='mb-0'>
              <p className='mb-0'>
                <strong>{nameForMode(layout)}:</strong>
                &nbsp; { descriptionForMode(layout) }
              </p>
            </Alert>
            <div className='form-group'>
              <label className='form-label'>Name</label>
              <Field
                type='text'
                name='headline'
                autoFocus={true}
                className='form-control'
                aria-describedby='headlineHelpBlock'
              />
              <BSForm.Text id="headlineHelpBlock" muted>
                Some domains may use this name as the page headline.
              </BSForm.Text>
            </div>
            {showsField(layout, 'subline') && (
              <div className='form-group'>
                <label className='form-label'>Subline</label>
                <Field
                  type='text'
                  name='subline'
                  className='form-control'
                />
                <div className='form-text'>
                  May also be displayed as an overline depending on Layout/Design
                </div>
              </div>
            )}
            {showsField(layout, 'lead_text') && (
              <div className='form-group'>
                <label className='form-label'>Lead Text</label>
                <Field
                  type='text'
                  as='textarea'
                  rows='3'
                  name='lead_text'
                  className='form-control'
                />
                <div className='form-text'>
                  No formatting (no links, no bold, no lists)
                </div>
              </div>
            )}
            {showsField(layout, 'image') && (
              <div className='form-group'>
                <label className='form-label'>Image</label>
                {image_url && (
                  <div className='mb-2'>
                    <img src={image_url} className='img-fluid mb-1' alt='' />
                  </div>
                )}
                <ImagePicker
                  name='image_id'
                />
                <ImageUrlField
                  name='image_url'
                  imageWidth={page_image_config.width}
                  imageHeight={page_image_config.height}
                />
              </div>
            )}
            <div className='form-group'>
              <label className='form-label'>Path</label>
              <Field
                type='text'
                name='path'
                autoFocus={false}
                className='form-control'
              />
              <div className='form-text text-danger small'>
                Beware: Changing the path is dangerous - when path is used as link text on other pages
              </div>
            </div>
            <div className='form-group'>
              <div className="form-check">
                <Field
                  type='checkbox'
                  id='published'
                  name='published'
                  className='form-check-input'
                />
                <label className="form-check-label" htmlFor="published">
                  Published
                </label>
              </div>
            </div>

            <div className='hstack gap-2'>
              <button
                type='submit'
                disabled={isSubmitting}
                className='btn btn-sm btn-outline-primary'
              >
                Save
              </button>
              <button
                type='button'
                disabled={isSubmitting}
                onClick={onCancel}
                className='btn btn-sm btn-outline-secondary'
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default PageForm
