import { useField } from 'formik'
import { useState } from 'react'

const ExtendableSelectField = ({ children, ...props }) => {
  const [field,] = useField(props)
  const [showInput, setShowInput] = useState(false)

  if (showInput) {
    return (
      <div className='input-group'>
        <input autoFocus type='text' {...field} className='form-control' />
        <button
          type='button'
          className='btn btn-outline-info'
          onClick={() => {
            setShowInput(false)
          }}
        >
          Choose existing Category
        </button>
      </div>
    )
  } else {
    return (
      <div className='input-group'>
        <select {...field} {...props} className='form-select'>
          {children}
        </select>
        <button
          type='button'
          className='btn btn-outline-info'
          onClick={() => {
            setShowInput(true)
          }}
        >
          New Category
        </button>
      </div>
    )
  }
}

export default ExtendableSelectField
