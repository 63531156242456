import React, {useContext} from 'react'

import {Formik, Form, Field} from 'formik'
import {useQueryClient, useMutation} from 'react-query'

import {ApiContext} from '../ApiContext'
import {MessagesContext} from '../MessagesContext'

const ComponentForm = ({onSuccess, onCancel, id, ...props}) => {
  const queryClient = useQueryClient()
  const {client} = useContext(ApiContext)
  const {addMessage} = useContext(MessagesContext)

  const createOrUpdateComponent = useMutation(component => {
    if (id) {
      return client.updateComponent(id, component)
    } else {
      return client.createComponent(component)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        ['components']
      )
    }
  })

  const validate = (values) => {
    const errors = {}

    try {
      JSON.parse(values.schema)
    } catch(err) {
      errors.schema = err.message
    }

    return errors
  }

  return (
    <div className='bg-warning bg-opacity-10 p-3'>
      <Formik
        initialValues={props}
        validate={validate}
        onSubmit={(values, formikBag) => {
          createOrUpdateComponent.mutate(values, {
            onSuccess: () => {
              formikBag.setSubmitting(false)
              onSuccess()
            },
            onError: (err) => {
              addMessage(`Cannot save Component: ${err.message}`, 'error')
              formikBag.setSubmitting(false)
            }
          })
        }}
      >
        {({isSubmitting, errors, touched, values: { image_url }}) => (
          <Form>
            <div className='form-group mb-3'>
              <label>Name</label>
              <Field
                type='text'
                name='name'
                autoFocus={true}
                className='form-control'
              />
            </div>
            <div className='form-group mb-3'>
              <label>Display Name</label>
              <Field
                type='text'
                name='display_name'
                className='form-control'
              />
            </div>
            <div className='form-group mb-3'>
              <label>Description</label>
              <Field
                type='text'
                name='description'
                rows={5}
                as='textarea'
                className='form-control'
              />
              {errors.description && touched.description && <div className='text-danger'>{errors.description}</div>}
            </div>
            <div className='form-group mb-3'>
              <label>Schema</label>
              <Field
                type='text'
                name='schema'
                rows={10}
                as='textarea'
                className='form-control'
              />
              {errors.schema && touched.schema && <div className='text-danger'>{errors.schema}</div>}
            </div>
            <button
              type='submit'
              disabled={isSubmitting}
              className='btn btn-sm btn-outline-primary me-2'
            >
              Save
            </button>
            <button
              type='button'
              disabled={isSubmitting}
              onClick={onCancel}
              className='btn btn-sm btn-outline-secondary'
            >
              Cancel
            </button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ComponentForm
