import React from 'react'

import PropTypes from 'prop-types'

const Cards = ({onSelect, components}) => {

  return (
    <>
      <p>
        Choose a Component
      </p>
      <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3'>
        {components.map((component) => (
          <div className='col' key={component.name}>
            <div className='card h-100'>
              <div className='card-header'>
                {component.display_name}
              </div>
              <div className='card-body d-flex flex-column'>
                <p className='flex-grow-1'>
                  {component.description}
                </p>
                <button
                  type='button'
                  className='btn btn-outline-info'
                  onClick={() => onSelect(component)}
                >
                  Select
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

Cards.propTypes = {
  onSelect: PropTypes.func.isRequired
}

export default Cards
