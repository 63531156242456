import { Alert } from "react-bootstrap"

const AlertFormFields = () => (
  <Alert variant='warning' className='m-0'>
    <strong>Heads Up!</strong> This form shows all available fields but
    only some may be shown live. The description below should tell what fields
    to fill in.
  </Alert>
)

export default AlertFormFields
