import React from 'react'
import {useNavigate} from 'react-router-dom'

import DomainForm from './DomainForm'

const DomainNew = () => {
  const navigate = useNavigate()

  const newDomain = {
    fqdn: '',
    slug: '',
    description: '',
    default_path_prefix: '',
    ci_user: '',
    ci_user_token: '',
    ci_webhook_url: '',
    ci_webhook_token: '',
  }
  
  return (
    <>
      <h1>New Domain</h1>
      <DomainForm
        {...newDomain}
        onSuccess={() => navigate('/admin/domains')}
        onCancel={() => navigate('/admin/domains')}
      />
    </>
  )
}

export default DomainNew
