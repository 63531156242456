import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { remarkDefinitionList, defListHastHandlers } from 'remark-definition-list'
import rehypeRaw from 'rehype-raw'
import {useParams} from 'react-router-dom'
import {Spinner} from 'react-bootstrap'

import useDomain from '../hooks/useDomain'

import MarkdownLink from './MarkdownLink'

const Markdown = ({children}) => {
  const {domainId} = useParams()
  const domainQuery = useDomain()

  if (domainQuery.isLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (domainQuery.isError) {
    return (<p className='text-danger text-center'>{domainQuery.error.message}</p>)
  }

  const findPageByPath = (pathToFind) => {
    return domainQuery.data.pages.find(({path}) => path === pathToFind)
  }

  const cmsUriTransformer = (href, children, title) => {
    const first = href.charAt(0)
    if (first === '/') {
      const [path, anchor, ] = href.replace(/^\//, '').split('#')
      const page = findPageByPath(path)

      if (page) {
        if (anchor) {
          return `/domains/${domainId}/pages/${page.id}#${anchor}`
        } else {
          return `/domains/${domainId}/pages/${page.id}`
        }
      } else {
        console.log("absolute path in link that is not a page...", href)
        return href
      }
    } else {
      return href
    }
  }

  return (
    <ReactMarkdown
      transformLinkUri={cmsUriTransformer}
      components={{
        'a': MarkdownLink
      }}
      remarkPlugins={[remarkGfm, remarkDefinitionList]}
      rehypePlugins={[rehypeRaw]}
      remarkRehypeOptions={{
        handlers: {
          ...defListHastHandlers
        }
      }}
      >
      {children}
    </ReactMarkdown>
  )
}

export default Markdown
