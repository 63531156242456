import React, {useState} from 'react'

import Modal from 'react-bootstrap/Modal'
import { useParams } from 'react-router-dom'
import { useFormikContext, useField } from 'formik'
import PropTypes from 'prop-types'

import Table from './Table'

/**
 *
 * PagePicker is used by Item#New -
 * to copy Page attriutes headline, abstract, image
 * to new Item - as Item#Headline, Item#body, Item#Image
 *
**/


const PagePicker = ({pages, linkTextField, ...props}) => {
  const { domainId } = useParams()
  const [showsModal, setShowsModal] = useState(false)
  const [searchString, setSearchString] = useState('')
  const {setFieldValue, setFieldTouched} = useFormikContext()
  const [/* field */, meta, /* helper */] = useField(props)
  const {value} = meta



  const openPicker = () => {
    setShowsModal(true)
  }

  const closePicker = () => {
    setShowsModal(false)
  }

  const filteredPages = pages.filter(({headline, path}) => {
    const regexp = new RegExp(searchString, 'iu')
    return regexp.test(headline) || regexp.test(path)
  })

  const selectPage = ({page, copyAttributes}) => {
    setFieldValue(props.name, page.path)

    if (copyAttributes) {
      setFieldValue('headline', page.headline)
      setFieldValue(linkTextField, page.headline)

      if (page.abstract) {
        setFieldValue('body', page.abstract)
      }

      if (page.image_id) {
        setFieldValue('image_id', page.image_id)
        setFieldTouched('image_id')
      }
    }

    closePicker()
  }

  const unselectPage = () => {
    setFieldValue(props.name, null)
    closePicker()
  }

  const selectedPage = pages.find(({path}) => {
    return path === value
  })

  let renderableResults = (<></>)

  if (searchString.length > 0) {
    if (filteredPages.length > 0) {
      renderableResults = (
        <Table
          pages={filteredPages}
          onSelect={selectPage}
        />
      )
    } else {
      renderableResults = (
        <p>
          No Pages match your query
        </p>
      )
    }
  }

  let showHeadline

  if ( selectedPage ) {
    showHeadline = (
      <a href={`/domains/${domainId}/pages/${selectedPage.id}`}
         target='_blank'
         rel='noreferrer'
      >
        {selectedPage.headline} (<code>{selectedPage && selectedPage.path}</code>)
      </a>
    )
  } else {
    showHeadline = ( <span className='text-danger'>page gone</span> )
  }

  let renderable

  if (value) {
    renderable = (
      <div>
        <div className='me-2 mb-2'>
          {showHeadline}
        </div>
        <button
          type='button'
          className='btn btn-sm btn-outline-danger'
          onClick={() => unselectPage()}
        >
          Remove linked Page
        </button>
      </div>
    )
  } else {
    renderable = (
      <>
        <button
          className='btn btn-sm btn-outline-info me-2'
          type='button'
          onClick={openPicker}
        >
          Select existing Page
        </button>
      </>
    )
  }

  return (
    <>
      <Modal
        show={showsModal}
        onHide={closePicker}
        dialogClassName='modal-lg'
        contentClassName='bg-dark text-light'
        animation={false}
        autoFocus={true}
      >
        <Modal.Header closeButton={true}>
          <Modal.Title>Select an existing Page</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Start entering Headline/Path of page you look for...
          </p>
          <input
            type='text'
            onChange={({target: {value}}) => setSearchString(value)}
            className='form-control mb-2'
            value={searchString}
            autoFocus={true}
          />
          {renderableResults}
        </Modal.Body>
      </Modal>
      {renderable}
    </>
  )
}

PagePicker.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number
    })
  ).isRequired
}

export default PagePicker
