import React, {useState} from 'react'

import Modal from 'react-bootstrap/Modal'
import {useFormikContext, useField} from 'formik'

const UrlPicker = (props) => {
  const [showsModal, setShowsModal] = useState(false)
  const [urlString, setUrlString] = useState('')
  const {setFieldValue} = useFormikContext()
  const [/* field */, meta, /* helper */] = useField(props)
  const {value} = meta

  const openUrlForm = () => {
    setShowsModal(true)
  }

  const closeUrlForm = () => {
    setShowsModal(false)
  }

  const removeUrl = () =>  {
    setFieldValue(props.name, null)
    closeUrlForm()
  }

  const setUrl = (url) => {
    if (url) {
      setFieldValue(props.name, url)
    }
    closeUrlForm()
  }

  let renderable

  if (value) {
    renderable = (
      <>
        <div className='mb-2'>
          <a href={value} target='_blank' rel='noreferrer'>{value}</a>
        </div>
        <button
          className='btn btn-sm btn-outline-danger'
          type='button'
          onClick={() => removeUrl()}
        >
          Remove External URL
        </button>
      </>
    )
  } else {
    renderable = (
      <>
        <button
          className='btn btn-sm btn-outline-info'
          type='button'
          onClick={openUrlForm}
        >
          Enter External Url
        </button>
      </>
    )
  }

  return (
    <>
      <Modal
        show={showsModal}
        onHide={closeUrlForm}
        autoFocus={true}
        animation={false}
        contentClassName='bg-dark text-light'
        dialogClassName='modal-lg'
      >
        <Modal.Header
          closeButton={true}
        >
          <Modal.Title>Enter URL</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='form-group mb-3'>
            <input
              type='text'
              className='form-control mb-2'
              autoFocus={true}
              onChange={({target: {value}}) => setUrlString(value)}
              value={urlString}
            />
          </div>
          <button
            type='button'
            onClick={() => setUrl(urlString)}
            className='btn btn-sm btn-outline-success'
          >
            Set URL
          </button>
        </Modal.Body>
      </Modal>
      {renderable}
    </>
  )
}

export default UrlPicker
