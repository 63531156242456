import React from 'react'
import ReactDOM from 'react-dom'
import {QueryClient} from 'react-query'
import {BrowserRouter} from 'react-router-dom'

import {ImageUrlSigner} from 'ak-react-utils'

import AkCmsRoot from './AkCmsRoot'
import AuthClient from './AuthClient'
import reportWebVitals from './reportWebVitals'

const rootEl = document.getElementById('ak-cms-admin')
const apiUrl = rootEl.dataset.apiUrl

const oauthApplicationId = rootEl.dataset.oauthApplicationId
const oauthProviderUrl = rootEl.dataset.oauthProviderUrl

const imageProxySalt = rootEl.dataset.imageProxySalt
const imageProxySecret = rootEl.dataset.imageProxySecret
const imageProxyHost = rootEl.dataset.imageProxyHost

const imageLibraryApiUrl = rootEl.dataset.imageLibraryApiUrl
const imageLibraryApiToken = rootEl.dataset.imageLibraryApiToken
const imageLibraryScope = rootEl.dataset.imageLibraryScope

if (!apiUrl
    || !oauthApplicationId
    || !oauthProviderUrl
    || !imageProxySalt
    || !imageProxySecret
    || !imageProxyHost
    || !imageLibraryApiUrl
    || !imageLibraryApiToken
    || !imageLibraryScope
) {
  alert(`AkCMSMgmt UI Configuration data-attribute missing!
Check all of the following:
oauth-application-id
oauth-provider-url
image-proxy-salt
image-proxy-secret
image-proxy-host
image-library-api-url
image-library-api-token
image-library-scope`)
} else {
  const imageUrlSigner = new ImageUrlSigner(imageProxySalt, imageProxySecret, imageProxyHost)

  const authClient = new AuthClient({
    clientId: oauthApplicationId,
    providerUrl: oauthProviderUrl,
  })

  const queryClient = new QueryClient()

  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <AkCmsRoot
          apiUrl={apiUrl}
          authClient={authClient}
          queryClient={queryClient}
          imageUrlSigner={imageUrlSigner}
          imageLibraryApiUrl={imageLibraryApiUrl}
          imageLibraryApiToken={imageLibraryApiToken}
          imageLibraryScope={imageLibraryScope}
        />
      </BrowserRouter>
    </React.StrictMode>,
    rootEl
  )

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
}
