import React from 'react'

import {Link} from 'react-router-dom'
import Nav from 'react-bootstrap/Nav'

const GuestNavLinks = () => {
  return (
    <Nav className="ms-auto">
      <Nav.Link to='/login' as={Link}>Login</Nav.Link>
    </Nav>
  )
}

export default GuestNavLinks
