import React from 'react'
import {Field} from 'formik'

const TernaryField = ({className, ...field}) => {
  return (
    <>
      <div className='form-check'>
        <Field
          name={field.name}
          type='radio'
          id={`${field.name}-truthy`}
          value='on'
          className='form-check-input'
        />
        <label
          htmlFor={`${field.name}-truthy`}
          className='form-check-label'
        >
          True
        </label>
      </div>
      <div className='form-check'>
        <Field
          name={field.name}
          type='radio'
          id={`${field.name}-falsy`}
          value='off'
          className='form-check-input'
        />
        <label
          htmlFor={`${field.name}-falsy`}
        >
          False
        </label>
      </div>
      <div className='form-check'>
        <Field
          name={field.name}
          type='radio'
          id={`${field.name}-default`}
          value=''
          className='form-check-input'
        />
        <label
          htmlFor={`${field.name}-default`}
          className='form-check-label'
        >
          Default
        </label>
      </div>
    </>
  )
}

export default TernaryField
