import React from 'react'

import TextOrEnumField from './TextOrEnumField'
import TernaryField from './TernaryField'

const fieldMapping = {
  'string': TextOrEnumField,
  'boolean': TernaryField,
}

const DynamicField = ({className, ...field}) => {
  const ActualField = fieldMapping[field.type]
  return (
    <ActualField
      {...field}
      className='form-control'
      key={field.name}
    />
  )
}

export default DynamicField
