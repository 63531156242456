import React, {useEffect, useContext} from 'react'

import {useFormikContext} from 'formik'
import {useQuery} from 'react-query'
import {ImageUrlSigningContext} from 'ak-react-utils'
import ImageLibraryApiContext from '../ImageLibraryApiContext'

import { defaultImgConfig } from '../utils'

const ImageUrlField = ({imageWidth, imageHeight, ...props}) => {
  const {imageLibraryClient} = useContext(ImageLibraryApiContext)
  const {urlSigner} = useContext(ImageUrlSigningContext)
  const {values: {image_id}, touched, setFieldValue} = useFormikContext()
  const {isLoading, isError, error, data} = useQuery(['images'], () => {
    return imageLibraryClient.getImages()
  })

  useEffect(() => {
    if (isLoading || isError) {
      return
    }

    if (!touched.image_id) {
      return
    }

    if (image_id) {
      const img = data.find(({id}) => { return id === image_id })
      const imgConfig = Object.assign({}, defaultImgConfig, {
        width: imageWidth,
        height: imageHeight
      })

      setFieldValue(props.name, urlSigner.create(img.url, imgConfig))
    } else {
      setFieldValue(props.name, null)
    }
  }, [
    data,
    isError,
    isLoading,
    touched.image_id,
    image_id,
    props.name,
    setFieldValue,
    urlSigner,
    imageWidth,
    imageHeight
  ])

  if (isLoading) {
    return (
      <>
      </>
    )
  }

  if (isError) {
    return (
      <>
        <p className='text-danger'>{error.message}</p>
      </>
    )
  }

  return (
    <>
    </>
  )
}

export default ImageUrlField
