import React from 'react'
import PropTypes from 'prop-types'

import {QueryClient, QueryClientProvider} from 'react-query'

import {ImageUrlSigner, ImageUrlSigningProvider} from 'ak-react-utils'

import AkCmsAdmin from './AkCmsAdmin'
import {AuthContextProvider} from './AuthContext'
import {ImageLibraryApiContextProvider} from './ImageLibraryApiContext'
import {MessagesContextProvider} from './MessagesContext'

const AkCmsRoot = ({
  apiUrl,
  authClient,
  queryClient,
  imageUrlSigner,
  imageLibraryApiUrl,
  imageLibraryApiToken,
  imageLibraryScope,
}) => (
  <MessagesContextProvider messages={[]}>
    <ImageUrlSigningProvider imageUrlSigner={imageUrlSigner}>
      <AuthContextProvider authClient={authClient}>
        <QueryClientProvider client={queryClient}>
          <ImageLibraryApiContextProvider
            apiUrl={imageLibraryApiUrl}
            apiToken={imageLibraryApiToken}
            scope={imageLibraryScope}
          >
            <AkCmsAdmin apiUrl={apiUrl} />
          </ImageLibraryApiContextProvider>
        </QueryClientProvider>
      </AuthContextProvider>
    </ImageUrlSigningProvider>
  </MessagesContextProvider>
)

AkCmsRoot.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  authClient: PropTypes.shape({
    hasAccessToken: PropTypes.func,
    accessToken: PropTypes.func
  }).isRequired,
  queryClient: PropTypes.instanceOf(QueryClient).isRequired,
  imageUrlSigner: PropTypes.instanceOf(ImageUrlSigner).isRequired,
  imageLibraryApiUrl: PropTypes.string.isRequired,
  imageLibraryApiToken: PropTypes.string.isRequired,
  imageLibraryScope: PropTypes.string.isRequired,
}

export default AkCmsRoot
