import React, {useContext} from 'react'

import {Spinner} from 'react-bootstrap'
import {Formik, Form, Field} from 'formik'
import {useParams} from 'react-router-dom'
import {useMutation, useQueryClient} from 'react-query'

import {ApiContext} from '../ApiContext'
import {MessagesContext} from '../MessagesContext'

import useDomain from '../hooks/useDomain'

import MenuEntriesFieldArray from './MenuEntriesFieldArray'

const MenuForm = ({onSuccess, onCancel, ...menu}) => {
  const queryClient = useQueryClient()
  const {addMessage} = useContext(MessagesContext)

  const {domainId} = useParams()
  const {
    isLoading: domainIsLoading,
    isError: domainIsError,
    error: domainError,
    data: domain
  } = useDomain()

  const {client} = useContext(ApiContext)

  const createOrUpdateMenu = useMutation((menu) => {
    if (menu.id) {
      return client.updateMenu(domainId, menu.id, menu)
    } else {
      return client.createMenu(domainId, menu)
    }
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        ['domains', domainId],
      )
    }
  })

  const onSubmit = (values, formikBag) => {
    createOrUpdateMenu.mutate(values, {
      onSuccess: () => {
        formikBag.setSubmitting(false)
        onSuccess()
      },
      onError: (err) => {
        addMessage(`Cannot save Menu: ${err.message}`, 'error')
        formikBag.setSubmitting(false)
      }
    })
  }

  if (domainIsLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (domainIsError) {
    return (<span className='text-danger text-center'>{domainError}</span>)
  }

  return (
    <Formik
      initialValues={menu}
      onSubmit={onSubmit}
    >
      {({values, isSubmitting}) => (
        <Form>
          <div className='mb-3'>
            <label className='form-label'>Display Name</label>
            <Field type='text' name='display_name' className='form-control' />
          </div>
          <div className='mb-3'>
            <label className='form-label'>Name</label>
            <Field type='text' name='name' className='form-control' />
            <div className='form-text'>
              Internal name. <span className='text-danger'>Do not change</span> unless you have good reason to do so!
            </div>
          </div>
          <div className='mb-3'>
            <label className='form-label'>Entries</label>
            <MenuEntriesFieldArray name='entries' pages={domain.pages} />
          </div>
          <button
            type='submit'
            className='btn btn-outline-primary me-2'
          >
            Save
          </button>
          <button
            type='button'
            className='btn btn-outline-secondary'
            onClick={onCancel}
          >
            Cancel
          </button>
        </Form>
      )}
    </Formik>
  )
}

export default MenuForm
