import ClientOAuth2 from 'client-oauth2'
import {create as createPKCE} from 'pkce'

const random = () => {
  const array = new Uint32Array(1)
  return crypto.getRandomValues(array)[0].toString()
}

class AuthClient {
  constructor({clientId, providerUrl}) {
    this.client = new ClientOAuth2({
      clientId,
      accessTokenUri: `${providerUrl}/token`,
      authorizationUri: `${providerUrl}/authorize`,
      redirectUri: `${window.location.origin}/oauth/callback`,
      scopes: ['public']
    })

    this.storage = sessionStorage
  }

  // NOTE: this function will do a browser redirect to
  // our oauth provider for login. User will be redirected
  // back to the configured redirectUri and the react-app
  // has to handle it accordingly (see e.g. AuthCallback route)
  authorize({ originalUrl }) {
    // to be unique for every authorization-flow
    console.log("authorize... with originalUrl: ", originalUrl )
    const nonce = random()
    const {codeVerifier, codeChallenge} = createPKCE()

    this.storage.setItem('ak-auth-nonce', nonce)
    this.storage.setItem('ak-auth-pkce-code-verifier', codeVerifier)
    this.storage.setItem('ak-auth-pkce-code-challenge', codeChallenge)
    this.storage.setItem('ak-auth-original-url', originalUrl)

    window.location.href = this.client.code.getUri({
      state: nonce,
      query: {
        code_challenge: codeChallenge,
        code_challenge_method: 'S256'
      }
    })
  }

  async exchangeAuthCodeForAccessToken() {

    console.log("exchangeAuthCodeForAccessTokene...")
    const res = await this.client.code.getToken(window.location.href, {
      state: this.nonce(),
      body: {
        code_verifier: this.codeVerifier()
      }
    })
    const originalUrl = this.storage.getItem('ak-auth-original-url')

    console.log("originalUrl from storage: ", this.storage.getItem('ak-auth-original-url'))

    this.storage.setItem('ak-auth-access-token', res.accessToken)
    this.cleanup()

    return originalUrl
  }

  nonce() {
    return this.storage.getItem('ak-auth-nonce')
  }

  codeVerifier() {
    return this.storage.getItem('ak-auth-pkce-code-verifier')
  }

  codeChallenge() {
    return this.storage.getItem('ak-auth-pkce-code-verifier')
  }

  accessToken() {
    return this.storage.getItem('ak-auth-access-token')
  }

  hasPendingAccessToken() {
    return this.codeVerifier() && this.codeChallenge() && this.nonce()
  }

  hasAccessToken() {
    const accessToken = this.accessToken()

    return !!accessToken
  }

  cleanup() {
    this.storage.removeItem('ak-auth-pkce-code-verifier')
    this.storage.removeItem('ak-auth-pkce-code-challenge')
    this.storage.removeItem('ak-auth-nonce')
    this.storage.removeItem('ak-auth-original-url')
  }
}

export default AuthClient
