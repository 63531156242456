import {useContext} from 'react'
import ApiContext from '../ApiContext'
import {useParams} from 'react-router-dom'
import {useQuery} from 'react-query'

const useMenu = () => {
  const {client} = useContext(ApiContext)
  const {domainId, menuId} = useParams()
  return useQuery(['domains', domainId, 'menus', menuId], () => client.fetchMenu(domainId, menuId))
}

export default useMenu
