import React, {useState, useContext} from 'react'
import useUser from '../hooks/useUser'
import ApiContext from '../ApiContext'
import useDomains from '../hooks/useDomains'
import SubmittableDomainSelect from './SubmittableDomainSelect'
import {Link} from 'react-router-dom'
import {Spinner, Card, ListGroup} from 'react-bootstrap'
import {useMutation, useQueryClient} from 'react-query'

const User = () => {
  const queryClient = useQueryClient()
  const {client} = useContext(ApiContext)

  const {
    isLoading,
    isError,
    error,
    data: userData
  } = useUser()

  const {
    isLoading: isDomainsLoading,
    isError: isDomainsError,
    error: domainError,
    data: domainData
  } = useDomains()

  const [mode, setMode] = useState(null)

  const linkDomain = useMutation((domainId) => {
    return client.linkUserDomain(userData.id, domainId)
  },{
    onSuccess: () => {
      queryClient.invalidateQueries(
        ['users']
      )
    }
  })

  const unlinkDomain = useMutation((domainId) => {
    return client.unlinkUserDomain(userData.id, domainId)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        ['users']
      )
    }
  })

  const handleLinkDomain = (domainId) => {
    console.log("handleLinkDomain", domainId)
    linkDomain.mutate(domainId)
  }

  const handleUnlinkDomain = (domainId) => {
    console.log("handleUnlinkDomain", domainId)
    unlinkDomain.mutate(domainId)
  }

  if (isLoading || isDomainsLoading) {
    return (
     <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (isError || isDomainsError) {
    return (
      <p className='text-danger text-center'>
        {isError ? error.message : domainError.message}
      </p>
    )
  }


  const domainList = userData.domains.map((dom) => {
    return (
      <ListGroup.Item key={dom.id}>{dom.fqdn}</ListGroup.Item>
    )
  })

  const unlinkableDomainList = userData.domains.map((dom) => {
    return (
      <ListGroup.Item key={dom.id}>
        <button
          onClick={() => handleUnlinkDomain(dom.id)}
          type='button'
          className='btn-sm btn-outline-danger'
        >
          X
        </button> &nbsp;
        {dom.fqdn}
      </ListGroup.Item>
    )
  })

  const toggleMode = () => {
    if (mode === 'assign') {
      setMode(null)
    } else {
      setMode('assign')
    }
  }

  const selectableDomains = domainData.filter((dom) => {
    return !userData.domains.map((d) => d.id).includes(dom.id)
  })

  return (
    <>
      <h1>{userData.email}</h1>
      <p>{userData.is_admin ? 'admin' : ''}</p>

      <Card className='mb-5'>
        <Card.Header>
          <h5>Linked Domains</h5>
        </Card.Header>
        <ListGroup variant='flush'>
          { mode === 'assign'
            ? <>
                {unlinkableDomainList}
                <ListGroup.Item key='select'>
                  <SubmittableDomainSelect
                    className='form-select'
                    domains={selectableDomains}
                    onSubmit={ handleLinkDomain}
                  />
                </ListGroup.Item>
              </>
            : domainList
          }
          { (userData.domains.length < 1 && mode !== 'assign')
              &&
                <ListGroup.Item key='info'>
                  <p className='text-info'>No linked Domains</p>
                </ListGroup.Item>
          }
        </ListGroup>
        <Card.Footer>
          <button
            type='button'
            className='btn btn-sm btn-outline-secondary'
            onClick={() => toggleMode()}
          >
            {mode === 'assign' ? 'Lock' : 'Change'}
          </button>
        </Card.Footer>
      </Card>


      <Link to={`/admin/users/${userData.id}/edit`} className='btn btn-outline-primary'>
        Edit User
      </Link>
    </>
  )
}

export default User

/*
 <Route path=':domainId/settings'>
              <Route path='new' element={<SettingNew />}/>
              <Route path=':settingId/edit' element={<SettingEdit />}/>
            </Route>
            */
