import React from 'react'

import {dimensionsFromImageUrl} from 'ak-react-utils'

import {toSlug} from 'ak-react-utils'
import Item from './Item'
import Markdown from './Markdown'

const Section = (props) => {
  return (
    <>
      <section>
        {props.image_url && (
          <img
            src={props.image_url}
            className='img-fluid mb-3'
            alt=''
            {...dimensionsFromImageUrl(props.image_url)}
          />
        )}
        <div className='mb-3 small'>
          <h3 id={toSlug(props.headline)}>{props.headline}</h3>
        </div>

        {props.lead_text && (
          <div className='mb-3 small'>
            <p className='lead'>{props.lead_text}</p>
          </div>
        )}

        {props.body && (
          <div className='mb-3'>
            <Markdown>{props.body}</Markdown>
          </div>
        )}

        <div className='mb-4'>
          {props.children}
        </div>
        <div className='mb-3'>
          {props.items.filter((item) => { return item.published }).map((item) => (
            <div key={item.id} className='mb-4'>
              <Item {...item}>
              </Item>
            </div>
          )
          )}
        </div>
      </section>
    </>
  )
}

export default Section
