import React, {useState, useContext} from 'react'

import {Spinner} from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import {useFormikContext} from 'formik'

import Cards from './Cards'
import ConfigureForm from './ConfigureForm'
import ApiContext from '../../ApiContext'
import {useQuery} from 'react-query'

const ComponentConfigurator = ({name, nameField, enabledField}) => {
  const {values, setFieldValue} = useFormikContext()
  const [showsPicker, setShowsPicker] = useState(false)
  const [,setShowsConfigureForm] = useState(false)
  const {client} = useContext(ApiContext)
  const {isLoading, isError, error, data} = useQuery(['components'], () => {
    return client.fetchComponents()
  })

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (isError) {
    return (
      <span className='text-danger text-center'>{error}</span>
    )
  }

  const openPicker = () => {
    setShowsPicker(true)
  }

  const closePicker = () => {
    setShowsPicker(false)
  }

  const openConfigureForm = (component) => {
    setShowsConfigureForm(true)
    setFieldValue(enabledField, true)
    setFieldValue(nameField, component.name)
  }

  const completeConfiguration = (values) => {
    setFieldValue(name, JSON.stringify(values, null, "  "))
    setShowsConfigureForm(false)
    setShowsPicker(false)
  }

  const removeComponentConfig = () => {
    setFieldValue(nameField, null)
    setFieldValue(enabledField, false)
    setFieldValue(name, null)
  }

  const component = data.find(({name}) => {
    return name === values[nameField]
  })

  return (
    <div>
      {values.has_component
      ?
      (
      <>
        <div className='mb-2'>
          <span>{component.display_name}</span>
          <div className='form-text'>
            Configuration:<br/>
            <pre>
              <code>
                {values[name]}
              </code>
            </pre>
          </div>
          <button
            type='button'
            className='btn btn-sm btn-outline-info me-2'
            onClick={openPicker}
          >
            Edit component
          </button>
          <button
            type='button'
            className='btn btn-sm btn-outline-danger'
            onClick={removeComponentConfig}
          >
            Remove component
          </button>
        </div>
      </>
      )
    :
    (
      <button
        type='button'
        className='btn btn-sm btn-outline-info'
        onClick={openPicker}
      >
        Select Component
      </button>
    )
      }
      <Modal
        show={showsPicker}
        onHide={closePicker}
        dialogClassName='modal-lg'
      >
        <Modal.Header
          closeButton={true}>
          <Modal.Title>
            {values.has_component ? `Configure ${component.name}` : 'Component Picker'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {values.has_component
          ?
           (
             <ConfigureForm
               {...component}
               componentConfig={values[name] || '{}'}
               onFinish={completeConfiguration}
             />
           )
          :
           (
             <Cards
               components={data}
               onSelect={openConfigureForm}
             />
           )
          }
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ComponentConfigurator
