import React, {useContext} from 'react'
import {Spinner} from 'react-bootstrap'
import {useParams, useNavigate} from 'react-router-dom'
import {useQuery, useQueryClient, useMutation} from 'react-query'
import {Formik, Form, Field} from 'formik'

import {ApiContext} from '../ApiContext'
import {MessagesContext} from '../MessagesContext'

const DomainForm = () => {
  const queryClient = useQueryClient()
  const {client} = useContext(ApiContext)
  const {addMessage} = useContext(MessagesContext)
  
  const navigate = useNavigate()
  const {domainId} = useParams()
  const {isLoading, isError, error, data} = useQuery(['domains', domainId], client.fetchDomain(domainId))

  const updateDomain = useMutation(changedDomain => {
    return client.updateDomain(domainId, changedDomain)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(['domains', domainId])
    }
  })

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (isError) {
    return (<p className='text-danger text-center'>{error.message}</p>)
  }

  const {
    description,
    meta_data,
    footer_text
  } = data

  return (
    <>
      <h1>Edit Domain</h1>
      <Formik
        initialValues={{
          meta_data,
          description,
          footer_text,
        }}
        onSubmit={(values, formikBag) => {
          updateDomain.mutate(values, {
            onSuccess: () => navigate(`/domains/${domainId}`),
            onError: (err) => {
              addMessage(`Cannot save Domain: ${err.message}`, 'error')
              formikBag.setSubmitting(false)
            }
          })
        }}
      >
        {({isSubmitting}) => (
          <Form>
            <div className='form-group mb-3'>
              <label className='form-label'>Description</label>
              <Field
                type='text'
                as='textarea'
                name='description'
                className='form-control'
              />
              <div className='form-text'>
                Internal information for editors of this domain.
              </div>
            </div>
            <div className='form-group mb-3'>
              <label className='form-label'>Footer Text</label>
              <Field
                type='text'
                as='textarea'
                name='footer_text'
                className='form-control'
              />
            </div>
            <fieldset>
              <legend>
                Metadata
              </legend>
              <div className='form-group mb-3'>
                <label className='form-label'>Meta Title</label>
                <Field
                  type='text'
                  name='meta_data.meta_title'
                  className='form-control'
                />
              </div>
              <div className='form-group mb-3'>
                <label className='form-label'>Meta Keywords</label>
                <Field
                  type='text'
                  as='textarea'
                  name='meta_data.meta_keywords'
                  className='form-control'
                />
                <div className='form-text'>Comma separated</div>
              </div>
              <div className='form-group mb-3'>
                <label className='form-label'>Meta Description</label>
                <Field
                  type='text'
                  as='textarea'
                  name='meta_data.meta_description'
                  className='form-control'
                />
                <div className='form-text'>
                  Between 50 and 160 characters long
                </div>
              </div>
            </fieldset>
            <button
              type='submit'
              disabled={isSubmitting}
              className='btn btn-outline-primary me-2'
            >
              Save
            </button>
            <button
              type='button'
              disabled={isSubmitting}
              onClick={() => navigate(`/domains/${domainId}`)}
              className='btn btn-outline-secondary'
            >
              Cancel
            </button>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default DomainForm
