import React from 'react'

const WordCounter = ({ text }) => {
  const trimmedText = (text || '').trim()
  const numCharacters = trimmedText.length
  const numWords = trimmedText.split(/\s+/).length
  return (
    <div className='row small text-muted justify-content-end'>
      <div className='col-2 text-end'>
        <code className='text-body'>{numCharacters} Characters</code>
      </div>
      <div className='col-1 text-end'>
        <code className='text-body'>{numWords} Words</code>
      </div>
    </div>
  )
}

export default WordCounter
