import React, {useContext} from 'react'
import {useQuery, useMutation, useQueryClient} from 'react-query'
import ApiContext from '../ApiContext'
import {Link, useNavigate} from 'react-router-dom'
import {Table, Spinner} from 'react-bootstrap'

const AdminDomains = () => {
  const queryClient = useQueryClient()
  const {client} = useContext(ApiContext)
  const {isLoading, isError, error, data} = useQuery(['domains'], client.fetchDomains)
  const navigate = useNavigate()




  const destroyDomain = useMutation((domainId) => {
    return client.destroyDomain(domainId)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        ['domains']
      )
    }
  })


  const handleDestroy = (domainId) => {
    if (window.confirm(`Really destroy Domain ${domainId}?`)) {
      destroyDomain.mutate(domainId)
    }
  }

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spinner animation='grow' variant='warning' />
      </div>
    )
  }

  if (isError) {
    return (<p className='text-danger text-center'>Error {error.message}</p>)
  }

  const renderRows = data.map(({id, fqdn, slug}) => {
    return (
      <tr key={id}>
        <td>{fqdn}</td>
        <td>{slug}</td>
        <td className='text-end'>
          <Link  to={`/admin/domains/${id}`} className='btn btn-sm btn-outline-secondary me-2'>Show</Link>
          <Link  to={`/admin/domains/${id}/edit`} className='btn btn-sm btn-outline-primary me-2'>Edit Domain</Link>
          <button
            type='button'
            className='btn btn-sm btn-outline-danger'
            onClick={() => handleDestroy(id)}
          >
            Destroy Domain
          </button>
        </td>
      </tr>
    )
  })


  return (
    <>
      <h1>All Domains</h1>

      <Table responsive className='mb-5'>
        <thead>
          <tr>
            <th>FQDN</th>
            <th>Slug</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {renderRows}
        </tbody>
      </Table>

      <button
        type='button'
        className='btn btn-outline-primary'
        onClick={() => navigate('/admin/domains/new')}
      >
        New Domain
      </button>
    </>
  )
}

export default AdminDomains
